import { Tabs, TabsProps } from '@mui/material';

const AcTabs = (props: TabsProps) => {
  return (
    <Tabs
      {...props}
      sx={{
        borderBottom: '1px solid #7575752c',
        marginBottom: '1rem'
      }}
    >
      {props.children}
    </Tabs>
  );
};

export default AcTabs;
