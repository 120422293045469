import { useQuery } from '@tanstack/react-query';

import { EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';

import { EApiRoutes, fetchConfig, getApiRouteOrMock } from './api.utils';

export default function useRoles(projectId?: string) {
  const axios = useAxios();

  const getRoles = useQuery<any, Error>({
    retry: false,
    queryKey: [EQueryKeys.ROLES],
    queryFn: async () => {
      return await axios.get(`${getApiRouteOrMock(EApiRoutes.ROLES)}`);
    }
  });

  return {
    getRoles
  };
}
