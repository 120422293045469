import { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { Box, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import usePermissions from 'api/usePermissions';
import { publisherActions } from 'store/publisherSlice';

import usePublisher from '../../api/usePublisher';
import useTheme from '../../api/useTheme';
import useUsers from '../../api/useUsers';
import { EPublisherSolutionModel, Project, User } from '../../common/contracts';
import { NEW_VERSION } from '../../constants/constants';
import {
  EBundlesInternalViewModel,
  ELocalStorageKeys
} from '../../constants/enums';
import { authActions } from '../../store/authSlice';
import { AuthSliceState } from '../../store/store.types';
import { localStorageUtil } from '../../utils/localStorageUtil';
import { permissionsUtil } from '../../utils/permissionsUtil';
import AcAvatar from '../AcAvatar/AcAvatar';

import { SidebarProps } from './Sidebar.types';
import SidebarDevDialog from './SidebarDevDialog';
import SidebarMenu from './SidebarMenu/SidebarMenu';
import { SidebarMenuItem } from './SidebarMenu/SidebarMenu.types';
import SidebarProfile from './SidebarProfile/SidebarProfile';
import { getMainNavItems, getSubNavItems } from './utils';

import './style.scss';

const Sidebar = ({ updateMainSize, isSidebarOpen }: SidebarProps) => {
  const pathParam = window.location.pathname.split('/')[2];
  const [isNewReport, setIsNewReport] = useState<boolean>(false);

  const versionDetails = localStorageUtil.getAny<User>(
    ELocalStorageKeys.USER_DETAILS
  )?.version;

  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [showEnvPicker, setShowEnvPicker] = useState<boolean>(false);
  const { getStoreTheme } = useTheme(currentPublisherId);
  const { logout } = useUsers(false, versionDetails, currentPublisherId, false);
  const [isProductMadness, setIsProductMadness] = useState<boolean>(false);
  const [isBackBtnShown, setIsBackBtnShown] = useState(false);
  const [isBackBtnClicked, setIsBackBtnClicked] = useState(false);
  const [currentProjectData, setCurrentProjectData] = useState<
    Project | undefined
  >();
  const { getPermissions } = usePermissions();
  const dispatch = useDispatch();
  const { getPublisher } = usePublisher(currentPublisherId || undefined);
  const { id } = useParams();
  const location = useLocation();

  useEffect(() => {
    const currentProject = projectsDetails?.find(
      (item) => item.publisherId === currentPublisherId
    );
    if (currentProject) {
      setCurrentProjectData(currentProject);
    }
  }, [currentPublisherId]);

  const projectsDetails = localStorageUtil.getAny<User>(
    ELocalStorageKeys.USER_DETAILS
  )?.projects;
  const isProjectItem = useMemo(() => {
    return projectsDetails?.some(
      (project) => project.publisherId === pathParam
    );
  }, [projectsDetails, pathParam]);
  const projectItem = projectsDetails?.find(
    (project) => project.publisherId === pathParam
  );
  const isProjectSelected = projectsDetails?.some(
    (item) => item.publisherId === pathParam
  );

  const { fetchFeatureFlags } = useUsers(
    false,
    versionDetails,
    currentPublisherId,
    isProjectItem
  );

  const isProjectCheckout =
    versionDetails === NEW_VERSION
      ? projectItem?.projectType === EPublisherSolutionModel.CHECKOUT
      : permissionsUtil.isCheckout();

  const featureFlags = fetchFeatureFlags.data?.featureFlags;
  const [navItems, setNavItems] = useState<SidebarMenuItem[]>([]);
  const permissions = getPermissions();

  const allowedPublishers =
    permissionsUtil.getAccessPublisherOrders(permissions);

  useEffect(() => {
    if (
      featureFlags &&
      (currentPublisherId || versionDetails !== NEW_VERSION)
    ) {
      dispatch(publisherActions.setActivePublisherId(currentProjectData));
      setNavItems(
        getSubNavItems(
          isNewReport,
          currentPublisherId ?? undefined,
          featureFlags,
          versionDetails,
          permissions,
          isProjectCheckout
        )
      );
      return;
    }

    setNavItems(getMainNavItems(projectsDetails, isNewReport, permissions));
  }, [currentPublisherId, versionDetails, featureFlags]);

  useEffect(() => {
    const bundleViewModel =
      getStoreTheme?.data?.general?.bundlesInternalViewModel;
    setIsProductMadness(
      [
        EBundlesInternalViewModel.JACKPOT_SLOTS,
        EBundlesInternalViewModel.BIG_FISH_CASINO
      ].includes(bundleViewModel)
    );
  }, [getStoreTheme?.data?.general?.bundlesInternalViewModel]);

  useEffect(() => {
    if (isBackBtnClicked) {
      setNavItems(getMainNavItems(projectsDetails, isNewReport, permissions));
      setIsBackBtnShown(false);
    }

    if (pathParam) {
      const isProjectItem = projectsDetails?.some(
        (project) => project.publisherId === pathParam
      );

      const activeProject = isProjectItem ? pathParam : undefined;

      if (isProjectItem) {
        dispatch(
          authActions.setCurrentPublisherId({
            currentPublisherId: pathParam
          })
        );
        setNavItems(
          getSubNavItems(
            isNewReport,
            activeProject,
            featureFlags,
            versionDetails,
            permissions,
            isProjectCheckout
          )
        );
        setIsBackBtnShown(true);
      }
    }
  }, [
    navigate,
    isBackBtnClicked,
    pathParam,
    fetchFeatureFlags.data,
    currentPublisherId
  ]);

  useEffect(() => {
    if (!pathParam) {
      setIsBackBtnShown(false);
    }
  }, [navigate, pathParam]);

  useEffect(() => {
    if (versionDetails === NEW_VERSION && id) {
      dispatch(
        authActions.setCurrentPublisherId({
          currentPublisherId: id
        })
      );
    }
  }, [location]);

  const toggleSideBar = () => {
    setStatus(isSidebarOpen ? 'closed' : '');
    updateMainSize(!isSidebarOpen);
  };

  const handleBackBtnClick = () => {
    setIsBackBtnClicked(true);
    let navigationPath = '/project';
    if (allowedPublishers?.length > 0) {
      navigationPath = '/project/orders';
    }
    navigate(navigationPath);
    dispatch(
      authActions.setCurrentPublisherId({
        currentPublisherId: null
      })
    );
  };

  const getCompanyName = () => {
    if (!currentProjectData || !isBackBtnShown) {
      return versionDetails === NEW_VERSION
        ? localStorageUtil.getAny<User>(ELocalStorageKeys.USER_DETAILS)?.company
        : getPublisher?.data?.companyName;
    }
    return projectItem?.publisherName;
  };

  return (
    <div id="sideNav" className={status}>
      <div className="arrow-container" onClick={toggleSideBar}>
        <ArrowBackIosRoundedIcon className="arrow" />
      </div>
      <Stack
        style={{
          height: '100%',
          width: '100%',
          justifyContent: 'space-between'
        }}
      >
        <Stack
          style={{
            height: 'calc(100% - 100px)',
            overflow: 'auto',
            overflowX: 'hidden'
          }}
        >
          {!getStoreTheme.isLoading ||
          !getStoreTheme.isFetching ||
          !getPublisher.isFetching ||
          !getPublisher.isLoading ? (
            <>
              <div className="logoContainer">
                <img
                  className="logo"
                  alt="logo"
                  src={`${process.env.PUBLIC_URL}/assets/images/logo_purple.svg`}
                  onClick={(e) => {
                    process.env.NODE_ENV === 'development' &&
                      e.detail === 3 &&
                      setShowEnvPicker(!showEnvPicker);
                  }}
                />
                {(process.env.NODE_ENV === 'development' ||
                  process.env.ENVIRONMENT === 'Dev' ||
                  process.env.ENVIRONMENT === 'Staging') && (
                  <span>
                    {process.env.ENVIRONMENT === 'Dev' ||
                    process.env.NODE_ENV === 'development'
                      ? 'DEVELOPMENT'
                      : 'STAGING'}
                  </span>
                )}
                {versionDetails === NEW_VERSION && currentPublisherId && (
                  <Stack justifyContent="center" alignItems="center">
                    {!!permissionsUtil.getRoleByPublisherId(
                      currentPublisherId
                    ) && (
                      <span className="role-tag">
                        {permissionsUtil.getRoleByPublisherId(
                          currentPublisherId
                        )}
                      </span>
                    )}
                  </Stack>
                )}
                {localStorageUtil.get(ELocalStorageKeys.ENVIRONMENT) && (
                  <span
                    className="api-env"
                    onClick={() => {
                      localStorageUtil.removeAny(ELocalStorageKeys.ENVIRONMENT);
                      window.location.reload();
                      logout.mutate();
                    }}
                  >
                    API:{' '}
                    {
                      localStorageUtil
                        .get(ELocalStorageKeys.ENVIRONMENT)
                        .match(/(?<=api-)[^.]+/)[0]
                    }
                    <br />
                    <span>click to reset</span>
                  </span>
                )}
                {process.env.NODE_ENV === 'development' && (
                  <SidebarDevDialog
                    showEnvPicker={showEnvPicker}
                    setShowEnvPicker={setShowEnvPicker}
                  />
                )}
              </div>
              <Box width="96%" mb={-1}>
                <Box my={1}>
                  <Stack direction="column">
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={0.5}
                      ml={1.25}
                      my={0.5}
                      className={
                        isSidebarOpen ? 'store-logo-open' : 'store-logo-closed'
                      }
                    >
                      {isBackBtnShown && isSidebarOpen && (
                        <IconButton
                          className="back-button"
                          aria-label="back"
                          size="small"
                          onClick={handleBackBtnClick}
                        >
                          <ArrowBackIosIcon />
                        </IconButton>
                      )}
                      {!isBackBtnShown && versionDetails === NEW_VERSION ? (
                        <AcAvatar content={getCompanyName()?.charAt(0)} />
                      ) : (
                        <>
                          {!getStoreTheme.isLoading && (
                            <img
                              style={{
                                display: 'none'
                              }}
                              src={
                                !isBackBtnShown
                                  ? getStoreTheme.data?.general.logo
                                  : projectItem?.publisherLogo
                              }
                              alt="store logo"
                            />
                          )}
                        </>
                      )}
                      <Typography fontFamily="'Montserrat', sans-serif">
                        {getCompanyName()}
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
              </Box>
              {navItems?.length > 0 && (
                <>
                  <ul
                    className={`navGroup ${versionDetails === NEW_VERSION ? 'navGroup-main' : ''}`}
                  >
                    <SidebarMenu
                      name="Management"
                      items={navItems}
                      group="overview"
                    />
                  </ul>
                  {versionDetails === NEW_VERSION && (
                    <ul className="navGroup">
                      <SidebarMenu
                        name="Management"
                        items={navItems}
                        group="management"
                      />
                    </ul>
                  )}
                  {!isProjectSelected && versionDetails === NEW_VERSION && (
                    <ul className="navGroup">
                      <SidebarMenu
                        name="Projects"
                        items={navItems}
                        group="projects"
                      />
                    </ul>
                  )}
                  <ul className="navGroup">
                    <SidebarMenu
                      name="Studio"
                      items={navItems}
                      group="studio"
                    />
                  </ul>
                  <ul className="navGroup">
                    <SidebarMenu
                      name="offers"
                      items={navItems}
                      group="offers"
                    />
                  </ul>
                  {versionDetails !== NEW_VERSION && (
                    <ul className="navGroup">
                      <SidebarMenu
                        name="Reports"
                        items={navItems}
                        group="reports"
                      />
                    </ul>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <Stack direction="row" gap={2} py={2} justifyContent="center">
                <Skeleton variant="circular" width={40} height={40} />

                <Skeleton variant="text" width={'60%'} />
              </Stack>
              <Stack alignItems="center" gap={4}>
                {[1, 2, 3, 4, 5, 6].map((item) => (
                  <Skeleton
                    key={item}
                    variant="rectangular"
                    width={210}
                    height={40}
                  />
                ))}
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
      <div className="nav-profile-footer">
        <SidebarProfile isSidebarOpen={isSidebarOpen} />
      </div>
    </div>
  );
};

export default Sidebar;
