import { useEffect, useState } from 'react';

import { Box, CircularProgress, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { required } from '../../utils/errorsTextHelper';
import ActionButton from '../ActionButton/ActionButton';

import { PasswordResetFormProps } from './ForgotPasswordForm.types';

import './style.scss';

const ForgotPasswordForm = ({
  sendResetEmail,
  setModalState
}: PasswordResetFormProps) => {
  let formSchema = yup.object().shape({
    email: yup.string().required(required('Email')).email('Enter a valid email')
  });
  const {
    values,
    errors,
    touched,
    dirty,
    handleChange,
    handleBlur,
    submitForm,
    isValid
  } = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: formSchema,
    onSubmit: async (values) => {
      values.email = values.email.toLowerCase();
      setIsLoading(true);
      sendResetEmail(values.email, () => {
        setIsLoading(false);
      });
    }
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    //Clear everything in local storage
    localStorage.clear();
  }, [values]);

  return (
    <>
      <Box className="header">
        <h1>Forgot Password</h1>
        <p>
          Enter your email and we'll send you instructions on how to reset your
          password.
        </p>
      </Box>
      <Box className="body">
        <form
          onSubmit={(e: React.SyntheticEvent) => {
            e.preventDefault();
          }}
        >
          <Box className="input short" style={{ height: '100px' }}>
            <label>Email</label>
            <TextField
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && !!errors.email}
              helperText={(touched.email && errors.email) || ''}
            />
          </Box>
          <Box className="actions">
            <p>
              Go back to{' '}
              <span onClick={() => setModalState('login')}>login page</span>
            </p>
          </Box>
          <ActionButton
            disabled={!!Object.keys(errors).length || !isValid || !dirty}
            type="submit"
            onClick={submitForm}
            variant="contained"
            style={{
              textTransform: 'capitalize',
              marginTop: '1em'
            }}
            text={
              isLoading ? (
                <CircularProgress style={{ width: 'auto', height: 'auto' }} />
              ) : (
                'Send'
              )
            }
          />
        </form>
      </Box>
    </>
  );
};

export default ForgotPasswordForm;
