import React, { useMemo, useState } from 'react';
import { Stack } from '@mui/system';
import useLocalization from 'api/useLocalization';
import { EButtonVariant } from 'constants/enums';
import { useFormik } from 'formik';
import {
  getCountriesOptions,
  getSupportedLanguageCodes,
  handleRenderCountriesList
} from 'views/Localization/utils';

import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';
import ActionButton from 'components/ActionButton/ActionButton';
import { LanguageDataParams } from 'views/Localization/components/types';

import { AddLanguageFormProps } from './AddLanguageForm.types';
import { addNewLanguageSchema } from './validation-schema';

import './style.scss';

const AddLanguageForm = ({
  onSave,
}: AddLanguageFormProps) => {
  const { getAddedLanguagesList } = useLocalization();

  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);

  const countriesOptions = useMemo(() => getCountriesOptions(), []);
  const languageCodesOptions = useMemo(() => getSupportedLanguageCodes(), []);

  const addedLanguagesList = getAddedLanguagesList?.data?.map((item: LanguageDataParams) => item?.language?.toLowerCase());
  const existingLanguageCodesList = getAddedLanguagesList?.data?.map((item: LanguageDataParams) => item?.isoCode?.toLowerCase());

  const handleSelectCountry = (arrSelectedCountries: string[]) => {
    const newSelectedValues = arrSelectedCountries.filter(
      (item: string) => item !== ''
    );
    setFieldValue('country', newSelectedValues, true);
    setSelectedCountries(newSelectedValues);
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    submitForm,
    isValid,
    setFieldValue
  } = useFormik({
    initialValues: {
      language: '',
      country: [],
      isEnabled: false,
      isoCode: ''
    },
    validationSchema: addNewLanguageSchema(addedLanguagesList,  existingLanguageCodesList),
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: (values, { resetForm }) => {
      const parsedLanguageData = values.country.map((country) => ({
        language: values.language,
        country:
          (countriesOptions.find((option) => option.value === country)
            ?.key as string) ?? '',
        isEnabled: values.isEnabled,
        isoCode: values.isoCode
      }));

      const newLanguagesList = getAddedLanguagesList?.data
          ? [...getAddedLanguagesList.data, ...parsedLanguageData]
          : parsedLanguageData;
      onSave(newLanguagesList);
      setSelectedCountries([]);
      resetForm();
    }
  });

  return (
    <form className="addLanguageForm" onSubmit={handleSubmit}>
      <Stack
        direction="row"
        alignItems="flex-start"
        className="addLanguageFormContainer"
      >
        <AcInput
          header="Language Display Name"
          name="language"
          value={values.language}
          onChange={handleChange}
          onBlur={handleBlur}
          customClass="addLanguageForm__input"
          error={touched.language && !!errors.language}
          helperText={(touched.language && errors.language) || ''}
        />
        <AcSelect
            name="isoCode"
            header="Language Code"
            value={values.isoCode}
            items={languageCodesOptions}
            onChange={handleChange}
            onBlur={handleBlur}
            isApplyBtnEnabled={false}
            className="addLanguageForm__select"
            error={!!errors.isoCode}
            helperText={(errors.isoCode as string) || ''}
            isSearchEnabled
        />
        <AcSelect
          name="country"
          header="Country"
          value={values.country}
          setSelectedItems={(values: string[]) => handleSelectCountry(values)}
          items={countriesOptions}
          isApplyBtnEnabled={false}
          renderValue={() => handleRenderCountriesList(selectedCountries)}
          className="addLanguageForm__select"
          error={!!errors.country}
          helperText={(errors.country as string) || ''}
          onBlur={handleBlur}
          isCheckBox
          isSearchEnabled
        />
        <ActionButton
          variant={EButtonVariant.OUTLINED}
          text={'Add New'}
          onClick={submitForm}
          disabled={!isValid}
        />
      </Stack>
    </form>
  );
};

export default AddLanguageForm;
