import dayjs, { Dayjs } from 'dayjs';

import { serializeDate } from './formattingUtil';

interface DateRangeAppliedProps {
  range: [Dayjs, Dayjs];
  setRangeDates: (dates: [Dayjs, Dayjs]) => void;
  setFormattedRangeDates: (formattedDates: [string, string]) => void;
  setPreviousFormattedRangeDates: (
    previousFormattedDates: [string, string]
  ) => void;
}

interface SameDateRangeAppliedProps extends DateRangeAppliedProps {
  hours: [string, string];
}

export const handleDateRangeApplied = ({
  range,
  setRangeDates,
  setFormattedRangeDates,
  setPreviousFormattedRangeDates
}: DateRangeAppliedProps) => {
  const [from, to] = range;

  const formattedFrom = serializeDate(from.toDate());
  const formattedTo = serializeDate(to.toDate(), true);
  const daysDiff = from.diff(to, 'day');

  setRangeDates([from, to]);
  setFormattedRangeDates([formattedFrom, formattedTo]);

  const previousFrom = from.subtract(Math.abs(daysDiff || 1), 'day');
  const previousTo = to
    .subtract(Math.abs(daysDiff || 1), 'day')
    .add(1, 'second');

  const formattedPreviousFrom = serializeDate(previousFrom.toDate());
  const formattedPreviousTo = serializeDate(previousTo.toDate(), true);

  setPreviousFormattedRangeDates([formattedPreviousFrom, formattedPreviousTo]);
};

export const handleSameDateRangeApplied = ({
  range,
  hours,
  setRangeDates,
  setFormattedRangeDates,
  setPreviousFormattedRangeDates
}: SameDateRangeAppliedProps) => {
  const datetimeObjects = range.map((date: any, index: any) => {
    const [hour, minute] = hours[index].split(':');
    return new Date(
      date.$d.getFullYear(),
      date.$d.getMonth(),
      date.$d.getDate(),
      parseInt(hour),
      parseInt(minute),
      0,
      0
    );
  });
  setRangeDates([dayjs(datetimeObjects[0]), dayjs(datetimeObjects[1])]);
  const formattedFrom = serializeDate(datetimeObjects[0]);
  const formattedTo = serializeDate(datetimeObjects[1]);

  setFormattedRangeDates([formattedFrom, formattedTo]);
  const timeDifference =
    datetimeObjects[1].getTime() - datetimeObjects[0].getTime();
  const previousStartTime = new Date(
    datetimeObjects[0].getTime() - timeDifference
  );
  const previousEndTime = new Date(
    datetimeObjects[1].getTime() - timeDifference
  );

  const formattedPreviousFrom = serializeDate(previousStartTime);
  const formattedPreviousTo = serializeDate(previousEndTime);

  setPreviousFormattedRangeDates([formattedPreviousFrom, formattedPreviousTo]);
};
