import { Navigate } from 'react-router-dom';

import { useLogin } from '../hooks/useLogin';

function PrivateRoute({ children }: { children: JSX.Element }) {
  const { isLoggedIn } = useLogin();
  return isLoggedIn() ? children : <Navigate to="/" />;
}

export default PrivateRoute;
