import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Stack, Typography } from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';
import dayjs from 'dayjs';

import { NEW_VERSION } from '../../../constants/constants';
import { StatusLabel } from '../../../design-system/StatusLabel/StatusLabel';
import {
  EStatusLabel,
  StatusLabelProps
} from '../../../design-system/StatusLabel/types';
import { permissionsUtil } from '../../../utils/permissionsUtil';
import AcAvatar from '../../AcAvatar/AcAvatar';
import AcSelect from '../../AcSelect/AcSelect';

const getInitials = (name: string) => {
  name.trim();
  return name?.split(' ')[0][0]?.toUpperCase() || '';
};

export const userStatusMap: Record<string, StatusLabelProps> = {
  [EStatusLabel.PENDING]: {
    text: 'Pending',
    status: EStatusLabel.PENDING,
    prefixIcon: <ScheduleIcon />
  },
  [EStatusLabel.ACTIVE]: {
    text: 'Active',
    status: EStatusLabel.SUCCESS,
    prefixIcon: <CheckCircleOutlineIcon />
  }
};
export const getUsersColumns = (
  handleChangeUserRole: (e: any, id: string, name: string) => void,
  versionDetails?: number,
  isAdmin?: boolean,
  handleDeleteAction?: (id: string) => void,
  newRolesOptions?: any
) => {
  const columns: any = [
    {
      field: 'name',
      minWidth: 200,
      headerName: 'Name',
      disableReorder: true,
      flex: 1,
      renderCell: (row: any) => {
        return (
          <Stack
            direction="row"
            gap={1}
            justifyContent="center"
            alignItems="center"
          >
            <AcAvatar content={getInitials(row?.value)} />
            <span>{row?.value}</span>
          </Stack>
        );
      }
    },
    {
      field: 'email',
      minWidth: 200,
      headerName: 'Email',
      disableReorder: true,
      flex: 1,
      renderCell: (row: any) => {
        return (
          <Stack
            direction="row"
            gap={1}
            justifyContent="center"
            alignItems="center"
          >
            <span>{row.value}</span>
          </Stack>
        );
      }
    },
    {
      field: 'role',
      headerName: 'Role',
      disableReorder: true,
      flex: 1,
      renderCell: (row: any) => {
        return (
          <Stack justifyContent="center" alignItems="center">
            <AcSelect
              size="small"
              value={row.value.toLowerCase()}
              disabled={
                (row.value.toLowerCase() === 'admin' &&
                  !permissionsUtil.isSuperAdmin()) ||
                permissionsUtil.isViewerOrEditor()
              }
              style={{
                height: '30px',
                fontSize: '14px',
                width: '120px',
                marginTop: '-1em'
              }}
              items={newRolesOptions ? newRolesOptions : userRolesOptions}
              onChange={(e: any) => {
                handleChangeUserRole(e, row.row._id, row.row.name);
              }}
            />
          </Stack>
        );
      }
    }
  ];

  if (versionDetails === NEW_VERSION) {
    columns.push({
      field: 'status',
      minWidth: 100,
      headerName: 'Status',
      disableReorder: true,
      flex: 1,
      hideable: false,
      renderCell: ({ row }: any) => {
        const currentStatus = row.active
          ? userStatusMap['active']
          : userStatusMap['pending'];
        return (
          <StatusLabel
            text={currentStatus.text}
            status={currentStatus.status}
            prefixIcon={currentStatus.prefixIcon}
          ></StatusLabel>
        );
      }
    });
  }

  if (versionDetails !== NEW_VERSION && isAdmin) {
    columns.concat([
      {
        field: 'lastActive',
        headerName: 'Last Active',
        disableReorder: true,
        flex: 1,
        renderCell: (row: any) => {
          return (
            <Typography variant="body2">
              {row.value ? dayjs(row.value).fromNow() : 'N/A'}
            </Typography>
          );
        }
      }
    ]);
  }

  if (isAdmin && handleDeleteAction) {
    columns.push({
      field: 'actions',
      flex: 0,
      type: 'actions',
      width: 80,
      disableReorder: true,
      getActions: (params: any) => [
        <GridActionsCellItem
          className="danger"
          icon={<DeleteOutlineTwoToneIcon className="danger" />}
          label="Delete"
          disabled={permissionsUtil.isViewerOrEditor()}
          onClick={() => handleDeleteAction(params.row._id)}
          showInMenu
        />
      ]
    });
  }
  return columns;
};

export const userRolesOptions = [
  {
    content: 'Viewer',
    key: 'viewer',
    value: 'viewer'
  },
  {
    content: 'Editor',
    key: 'editor',
    value: 'editor'
  },
  {
    content: 'Admin',
    key: 'admin',
    value: 'admin'
  }
];
