import Grid from '@mui/material/Grid2';
import { Stack } from '@mui/system';
import useLocalization from 'api/useLocalization';
import { AxiosError } from 'axios';
import { ENotificationType } from 'constants/enums';
import dayjs from 'dayjs';
import { useNotifications } from 'hooks/useNotifications';

import { errorResponse } from 'utils/errorsTextHelper';

import AcCard from 'components/AcCard/AcCard';
import ActionButton from 'components/ActionButton/ActionButton';

import './style.scss';

const ExportTranslationsCSVSection = () => {
    const {getStaticTranslationsCsvFile, getDynamicTranslationsCsvFile} = useLocalization();
    const { enqueueSnackbar } = useNotifications();

    const handleDataExportCSV = async (isDynamic = false) => {
        const response = isDynamic ? await getDynamicTranslationsCsvFile.refetch() : await getStaticTranslationsCsvFile.refetch();
        if (response.isError) {
            enqueueSnackbar(errorResponse(response.error as AxiosError), ENotificationType.ERROR);
            return
        }
            const { data } = response;
        if (data) {
            const url = window.URL.createObjectURL(new Blob([data], { type: 'text/csv' }));
            const link = document.createElement('a');
            const formattedDate = dayjs().format('YYYY-MM-DD_HH-mm-ss')
            link.href = url;
            link.setAttribute('download', `translations_${isDynamic ? 'dynamic' : 'static'}_${formattedDate}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }
    };

    return (
        <Grid container sx={{alignItems:"center"}} >
            <Grid size={12} sx={{position: "relative"}}>
                <AcCard
                    title={"2. Download Up-to-Date Translations File"}
                    description={"Download translations for static strings (e.g., labels, buttons) and dynamic strings (e.g., user-specific content)"}
                    stackContainer={false}
                    className="localizationCSVCard"
                    customTitleClass="localizationCSVCardTitle"
                    customDescriptionClass="localizationCSVCardDescription"
                    customStyles={{"border": "1px solid #cacbd4", "borderRadius": "4px"}}
                >
                    <Stack direction="row" spacing={2}>
                        <ActionButton
                            variant="outlined"
                            text="Download Static Strings"
                            onClick={() => handleDataExportCSV()}
                        />
                        <ActionButton
                            variant="outlined"
                            text="Download Dynamic Strings"
                            onClick={() => handleDataExportCSV(true)}
                        />
                    </Stack>
                </AcCard>
            </Grid>
        </Grid>
    )
};

export default ExportTranslationsCSVSection;
