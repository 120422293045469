import { EAppearanceStatus } from 'constants/enums';

// Helper function to determine the status based on current date and time frame
export const determineStatus = (
  startTime: Date,
  endTime: Date
): EAppearanceStatus => {
  const currentDate = new Date();

  if (currentDate >= startTime && currentDate <= endTime) {
    return EAppearanceStatus.RUNNING;
  } else if (currentDate < startTime) {
    return EAppearanceStatus.UPCOMING;
  } else {
    return EAppearanceStatus.ENDED;
  }
};

// Parse cron string back to hour and minute
const parseCronToHour = (cronString: string): string => {
  const [minute, hour] = cronString.split(' ');
  return `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`;
};

// Generate cron string from hour and minute
const generateCronString = (hourMinute: string): string => {
  const [hour, minute] = hourMinute.split(':').map(Number);
  return `${minute} ${hour} * * *`;
};

export const formatCronUtils = {
  parseCronToHour,
  generateCronString
};
