import React from 'react';

import { Box, Button } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { LoginTermsBlock } from './LoginTermsBlock';
import { ProvidersMode } from './providers';
import {
  StyledImageContainer,
  StyledLoginContainer,
  StyledLoginPage
} from './style';
import { LoginPage } from './types';

export const LoginPreview: React.FC<LoginPage> = (props) => {
  const {
    textSize = 24,
    textColor = 'blue',
    textWeight = 'normal',
    text = 'Welcome to appcharge store!',
    logo = 'https://media.appcharge.com/defaults/logo.svg',
    loginLogoSize,
    playerAuthData,
    width = '100vw',
    height = '100vh - 200px',
    fontFamily,
    otpButton,
    translations = {
      signInWithFacebook: 'Sign in with Facebook',
      signInWithGoogle: 'Sign in with Google',
      signInWithApple: 'Sign in with Apple',
      loginWithPlayerId: 'Login with Player ID',
      userName: 'Username',
      password: 'Password',
      login: 'Login',
      contactSupport: 'Contact support',
      privacyPolicy: 'Privacy Policy',
      termsAndConditions: 'Terms and Conditions',
      byLoggingIn:
        'By logging in you acknowledge that you have read and agree to the',
      and: 'And',
      back: 'Back',
      playerId: 'Player ID',
      leavingWebviewTitle: 'Leaving',
      leavingWebviewDescription:
        'Open this link in external browser to use your preferred login method',
      leavingWebviewButton: 'Open in an external browser',
      or: 'Or'
    }
  } = props;

  return (
    <GoogleOAuthProvider clientId={playerAuthData?.googleAppId || ''}>
      <StyledLoginPage
        fontFamily={fontFamily}
        textColor={textColor}
        textSize={textSize}
        textWeight={textWeight}
        width={width}
        height={height}
      >
        <StyledImageContainer>
          <img
            className="logo"
            id={loginLogoSize}
            alt="game logo"
            src={logo}
            style={{
              width: window.location.hostname.includes('matchmaste.rs')
                ? '230px'
                : 'auto'
            }}
          />
        </StyledImageContainer>
        <h2>{text}</h2>
        <StyledLoginContainer>
          <ProvidersMode
            playerAuthData={playerAuthData}
            translations={translations}
            otpButton={otpButton}
          />
        </StyledLoginContainer>
        {playerAuthData.otpOn && (
          <LoginTermsBlock
            textColor={textColor}
            translations={translations}
          ></LoginTermsBlock>
        )}
        <Box mt={2}>
          <Button
            variant="text"
            sx={{
              color: 'white',
              textTransform: 'none',
              fontSize: '16px'
            }}
            onClick={() => {}}
          >
            {translations.contactSupport}
          </Button>
        </Box>
      </StyledLoginPage>
    </GoogleOAuthProvider>
  );
};
