import { useState } from 'react';

import { Box, CircularProgress, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import useUrlQuery from '../../hooks/useUrlQuery';
import { required } from '../../utils/errorsTextHelper';
import ActionButton from '../ActionButton/ActionButton';

import { FinishAccountFormProps } from './FinishAccountForm.types';

import './style.scss';

const FinishAccountForm = ({
  setNewPassword,
  setModalState
}: FinishAccountFormProps) => {
  const token = useUrlQuery('token');
  const email = useUrlQuery('email');

  let formSchema = yup.object().shape({
    password1: yup
      .string()
      .label('Password')
      .password()
      .required(required('Password')),
    password2: yup
      .string()
      .label('Password')
      .oneOf([yup.ref('password1'), null], 'Passwords must match')
      .required()
  });
  const {
    values,
    errors,
    touched,
    dirty,
    handleChange,
    handleBlur,
    submitForm,
    isValid
  } = useFormik({
    initialValues: {
      email: email!,
      password1: '',
      password2: ''
    },
    validationSchema: formSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      const data = {
        email: email!.toLowerCase(),
        rawNewPassword: values.password1,
        token
      };
      setNewPassword(data, (success: boolean) => {
        setIsLoading(false);
        if (success) setModalState('login');
      });
    }
  });

  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <Box className="header">
        <h1>Create your Password</h1>
        <p>Choose your passwords for logging in to AppCharge's Dashboard.</p>
      </Box>
      <Box className="body">
        <form
          onSubmit={(e: React.SyntheticEvent) => {
            e.preventDefault();
          }}
        >
          <Box className="input short" style={{ height: '100px' }}>
            <label>Email</label>
            <TextField
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && !!errors.email}
              helperText={(touched.email && errors.email) || ''}
              disabled
            />
          </Box>
          <Box className="input short" style={{ height: '100px' }}>
            <label>Password</label>
            <TextField
              name="password1"
              type="password"
              value={values.password1}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password1 && Boolean(errors.password1)}
              helperText={touched.password1 ? errors.password1?.toString() : ''}
            />
          </Box>

          <p style={{ marginBottom: '20px' }}>
            Your password must be at least 8 characters long and contain at
            least one capital letter (A-Z), one lowercase letter (a-z), one
            numeral (0-9) and at least one special symbol (e.g., !, @, #, $, %,
            etc.).
          </p>
          <Box className="input short" style={{ height: '100px' }}>
            <label>Confirm Password</label>
            <TextField
              name="password2"
              type="password"
              value={values.password2}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password2 && Boolean(errors.password2)}
              helperText={touched.password2 ? errors.password2?.toString() : ''}
            />
          </Box>
          <Box className="actions">
            <p>
              Go back to{' '}
              <span onClick={() => setModalState('login')}>login page</span>
            </p>
          </Box>
          <ActionButton
            disabled={
              isLoading || !!Object.keys(errors).length || !isValid || !dirty
            }
            type="submit"
            onClick={submitForm}
            variant="contained"
            style={{
              textTransform: 'capitalize',
              marginTop: '1em'
            }}
            text={
              isLoading ? (
                <CircularProgress style={{ width: 'auto', height: 'auto' }} />
              ) : (
                'Send'
              )
            }
          />
        </form>
      </Box>
    </>
  );
};

export default FinishAccountForm;
