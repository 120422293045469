import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import UndoIcon from '@mui/icons-material/Undo';

import { EOrderStatus } from '../../../constants/enums';
import {
  EStatusLabel,
  StatusLabelProps
} from '../../../design-system/StatusLabel/types';

export interface OrdersTableProps {
  tab?: any;
  isLoading: boolean;
  isError: boolean;
  data: any;
}

export interface OrderTableColumns {
  paymentId: boolean;
  provider: boolean;
  bundleId: boolean;
  amountTotal: boolean;
  fbId: boolean;
  createdAt: boolean;
  packageName: boolean;
  status: boolean;
}

export const statusDictionary: Record<EOrderStatus, StatusLabelProps> = {
  [EOrderStatus.unpaid]: {
    text: 'Pending',
    prefixIcon: <AccessTimeIcon />,
    status: EStatusLabel.CANCELED
  },
  [EOrderStatus.created]: {
    text: 'Created',
    prefixIcon: <AccessTimeIcon />,
    status: EStatusLabel.SUCCESS
  },
  [EOrderStatus.payment_pending]: {
    text: 'Payment Pending',
    prefixIcon: <AccessTimeIcon />,
    status: EStatusLabel.PENDING
  },
  [EOrderStatus.payment_succeed]: {
    text: 'Award Pending',
    prefixIcon: <AccessTimeIcon />,
    status: EStatusLabel.SUCCESS
  },
  [EOrderStatus.payment_failed]: {
    text: 'Failed (not charged)',
    prefixIcon: <DoNotDisturbIcon />,
    status: EStatusLabel.FAILED
  },
  [EOrderStatus.payment_refunded]: {
    text: 'Refunded',
    prefixIcon: <UndoIcon />,
    status: EStatusLabel.REFUNDED
  },
  [EOrderStatus.payment_refund_failed]: {
    text: 'Refund Failed',
    prefixIcon: <DoNotDisturbIcon />,
    status: EStatusLabel.REFUNDED
  },
  [EOrderStatus.payment_refund_cancelled]: {
    text: 'Refund Cancelled',
    prefixIcon: <DoNotDisturbIcon />,
    status: EStatusLabel.REFUNDED
  },
  [EOrderStatus.payment_canceled]: {
    text: 'Canceled',
    prefixIcon: <DoNotDisturbIcon />,
    status: EStatusLabel.CANCELED
  },
  [EOrderStatus.charge_pending]: {
    text: 'Award Pending',
    prefixIcon: <AccessTimeIcon />,
    status: EStatusLabel.SUCCESS
  },
  [EOrderStatus.charge_succeed]: {
    text: 'Completed Successfully',
    prefixIcon: <CheckCircleOutlineIcon />,
    status: EStatusLabel.SUCCESS
  },
  [EOrderStatus.charge_failed]: {
    text: 'Failed (charged)',
    prefixIcon: <DoNotDisturbIcon />,
    status: EStatusLabel.FAILED
  },
  [EOrderStatus.payment_refund_pending]: {
    text: 'Refund Pending',
    prefixIcon: <AccessTimeIcon />,
    status: EStatusLabel.PENDING
  },
  [EOrderStatus.payment_dispute_won]: {
    text: 'Dispute Won',
    prefixIcon: <CheckCircleOutlineIcon />,
    status: EStatusLabel.SUCCESS
  },
  [EOrderStatus.payment_dispute_open]: {
    text: 'Dispute Open',
    prefixIcon: <AccessTimeIcon />,
    status: EStatusLabel.CANCELED
  },
  [EOrderStatus.payment_dispute_lost]: {
    text: 'Dispute Lost',
    prefixIcon: <DoNotDisturbIcon />,
    status: EStatusLabel.FAILED
  },
  [EOrderStatus.payment_dispute_inquiry_won]: {
    text: 'Dispute Won',
    prefixIcon: <CheckCircleOutlineIcon />,
    status: EStatusLabel.SUCCESS
  },
  [EOrderStatus.payment_dispute_inquiry_evidence_submitted]: {
    text: 'Evidence Submitted',
    prefixIcon: <ArrowForwardIcon />,
    status: EStatusLabel.REFUNDED
  },
  [EOrderStatus.payment_dispute_evidence_submitted]: {
    text: 'Evidence Submitted',
    prefixIcon: <ArrowForwardIcon />,
    status: EStatusLabel.REFUNDED
  },
  [EOrderStatus.payment_dispute_inquiry_open]: {
    text: 'Dispute Open',
    prefixIcon: <AccessTimeIcon />,
    status: EStatusLabel.REFUNDED
  }
};

export const chargeFailedFreeOrder = 'Failed';
