import { Route, Routes } from 'react-router-dom';

import OffersUIForm from './OffersUIForm/OffersUIForm';
import OffersUITable from './OffersUITable/OffersUITable';

import '../../views/view.scss';

const OffersUIView = () => {
  return (
    <Routes>
      <Route path="/" element={<OffersUITable />} />
      <Route path="/form" element={<OffersUIForm />} />
      <Route path="/form/:offerUIId" element={<OffersUIForm edit={true} />} />
      <Route
        path="/dup/:offerUIId"
        element={<OffersUIForm edit={false} dup={true} />}
      />
    </Routes>
  );
};

export default OffersUIView;
