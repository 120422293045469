import { AxiosError } from 'axios';
export const required = (field: string): string => {
  return `${field} is required`;
};

export const min = (field: string, min: number): string => {
  return `${field} field must contain at least ${min} characters`;
};

export const positive = (field: string): string => {
  return `${field} must be a positive number`;
};

export const integer = (field: string): string => {
  return `${field} must be a whole number`;
};

export const errorResponse = (error: AxiosError) => {
  const responseData = error.response?.data as { message?: string };
  let errorMessage = responseData?.message || 'An unexpected error occurred';
  return errorMessage.replace(/^"|"$/g, '');
};
