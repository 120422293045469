export enum OfferDesignTypes {
  SpecialOffer = 'SpecialOffer',
  Bundle = 'Bundle',
  PopUp = 'PopUp'
}

export interface OfferDesignTypeItem {
  content: string;
  key: OfferDesignTypes;
  value: OfferDesignTypes;
}

export interface OffersUIFormProps {
  edit?: boolean;
  dup?: boolean;
}
