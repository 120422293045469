import { useState } from 'react';

import { Box } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import YupPassword from 'yup-password';

import useUrlQuery from '../../hooks/useUrlQuery';
import { required } from '../../utils/errorsTextHelper';
import AcInput from '../AcInput/AcInput';
import ActionButton from '../ActionButton/ActionButton';

import { PasswordResetFormProps } from './ResetPasswordForm.types';

import './style.scss';
YupPassword(yup);

const ResetPasswordForm = ({
  setNewPassword,
  setModalState
}: PasswordResetFormProps) => {
  const token = useUrlQuery('token');
  const email = useUrlQuery('email');
  let resetPasswordSchema = yup.object().shape({
    password1: yup.string().password().required(required('Password')),
    password2: yup
      .string()
      .oneOf([yup.ref('password1'), null], 'Passwords must match')
      .required()
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    submitForm,
    dirty,
    isValid
  } = useFormik({
    initialValues: {
      password1: '',
      password2: ''
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      const data = {
        email,
        rawNewPassword: values.password1,
        token
      };
      setNewPassword(data, (success: boolean) => {
        setIsLoading(false);
        if (success) setModalState('login');
      });
    }
  });

  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <Box className="header">
        <h1>Reset Password</h1>
      </Box>
      <Box className="body">
        <form
          onSubmit={(e: React.SyntheticEvent) => {
            e.preventDefault();
          }}
        >
          <Box className="input" style={{ height: '100px' }}>
            <label>Email</label>
            <AcInput value={email} disabled />
          </Box>
          <Box className="input">
            <label>New Password</label>
            <AcInput
              name="password1"
              type="password"
              value={values.password1}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password1 && Boolean(errors.password1)}
              helperText={touched.password1 ? errors.password1?.toString() : ''}
            />
          </Box>
          <p style={{ marginBottom: '20px' }}>
            Your password must be at least 8 characters long and contain at
            least one capital letter (A-Z), one lowercase letter (a-z), one
            numeral (0-9) and at least one special symbol (e.g., !, @, #, $, %,
            etc.)
          </p>
          <Box className="input">
            <label>Confirm Password</label>
            <AcInput
              name="password2"
              type="password"
              value={values.password2}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password2 && Boolean(errors.password2)}
              helperText={touched.password2 ? errors.password2?.toString() : ''}
            />
          </Box>
          <ActionButton
            disabled={!!Object.keys(errors).length || !isValid || !dirty}
            type="submit"
            onClick={submitForm}
            variant="contained"
            style={{ textTransform: 'capitalize' }}
            isLoading={isLoading}
            text={'Submit'}
          />
        </form>
      </Box>
    </>
  );
};

export default ResetPasswordForm;
