import * as yup from 'yup';

export const editLanguageSchema = (languagesList: string[], languagesCodes: string[]) => yup.object().shape({
  language: yup
      .string()
      .trim()
      .required('Language display name is required')
      .test('not-english', 'Language cannot be equal to the default system language', (value) => {
        return !!(value && value.toLowerCase() !== 'english' && value.toLowerCase() !== 'en');
      })
      .test('unique language', 'Language with such name already exists', (value) => {
          return !!(value && !languagesList.includes(value.toLowerCase()))
      }),
  isoCode: yup
      .string()
      .required('Language code is required')
      .test('no-default', 'Language code cannot be equal to "en"', (value) => {
         return !!(value && value.toLowerCase() !== 'en');
      })
      .test('unique language code', 'Language with such code already exists', (value) => {
          return !!(value && !languagesCodes.includes(value.toLowerCase()))
      }),
  country: yup
    .array()
    .of(yup.string())
    .test('not-empty', 'At least one country must be selected', (value) => {
      return !!(value && value.length > 0);
    })
    .test('no-default', 'Invalid country selected', (value) => {
      return !!(value && !value.includes('Country'));
    })
});
