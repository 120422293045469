import SendIcon from '@mui/icons-material/Send';
import { Box, Drawer, Grid } from '@mui/material';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';

import { permissionsUtil } from '../../../utils/permissionsUtil';
import AcInput from '../../AcInput/AcInput';
import AcSelect from '../../AcSelect/AcSelect';
import ActionButton from '../../ActionButton/ActionButton';

import { userRolesOptions } from './utils';

interface UserSettingsDrawerProps {
  addUserDrawerOpen: boolean;
  setAddUserDrawerOpen: (open: boolean) => void;
  values: FormikValues;
  handleChange: any;
  handleBlur: (e: React.FocusEvent<any>) => void;
  touched: FormikTouched<{ role: string; name: string; email: string }>;
  errors: FormikErrors<{ role: string; name: string; email: string }>;
  v2UserRolesOptions: any;
  isValid: boolean;
  dirty: boolean;
  submitForm: () => void;
}

const UserSettingsDrawer = ({
  addUserDrawerOpen,
  setAddUserDrawerOpen,
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  v2UserRolesOptions,
  isValid,
  dirty,
  submitForm
}: UserSettingsDrawerProps) => {
  return (
    <Drawer
      anchor="right"
      open={addUserDrawerOpen}
      onClose={() => setAddUserDrawerOpen(false)}
    >
      <Box width={400}>
        <Grid container padding={4} paddingTop={0.5} spacing={4}>
          <Grid xs={12} item>
            <h2>Invite User</h2>
          </Grid>
          <Grid xs={12} item marginTop={-2}>
            <AcInput
              header="Full Name"
              value={values.name}
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name ? errors.name?.toString() : ''}
            />
          </Grid>
          <Grid xs={12} item>
            <AcInput
              header="Email"
              value={values.email}
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && Boolean(errors.email)}
              helperText={
                errors.email
                  ? errors.email?.toString()
                  : 'Some emails may be redirected to your promotion tab'
              }
            />
          </Grid>
          <Grid xs={12} item>
            <AcSelect
              header="Role"
              name="role"
              value={values.role}
              defaultValue={v2UserRolesOptions?.[0]?.value || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.role && Boolean(errors.role)}
              items={
                permissionsUtil.isNewVersion()
                  ? v2UserRolesOptions
                  : userRolesOptions
              }
              helperText="Be mindful, this will affect your users access to your dashboard."
            />
          </Grid>
          <Grid xs={12} item>
            <ActionButton
              disabled={!!Object.keys(errors).length || !isValid || !dirty}
              text="Send Invite"
              icon={<SendIcon />}
              variant="contained"
              fullWidth={true}
              onClick={submitForm}
            />
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
};

export default UserSettingsDrawer;
