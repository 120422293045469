import dayjs from 'dayjs';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DoNotDisturbAltOutlinedIcon from '@mui/icons-material/DoNotDisturbAltOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

import { EStatusLabel } from 'design-system/StatusLabel/types';

export const STATUS_TO_ICON_MAP = {
  [EStatusLabel.SUCCESS]: <CheckCircleOutlineOutlinedIcon />,
  [EStatusLabel.FAILED]: <DoNotDisturbAltOutlinedIcon />,
  [EStatusLabel.PENDING]: <AccessTimeOutlinedIcon />
};

export function isDatePassed(timestamp: string) {
  return dayjs().isAfter(dayjs(timestamp));
}
