import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Grid, InputAdornment, OutlinedInput, Stack } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

import useAnalytics, {
  EMetric,
  metricDictionary
} from '../../api/useAnalytics';
import AcChartBox from '../../components/AcChartBox/AcChartBox';
import AcContentWrapper from '../../components/AcContentWrapper/AcContentWrapper';
import AcStatBox from '../../components/AcStatBox/AcStatBox';
import AcViewWrapper from '../../components/AcViewWrapper/AcViewWrapper';
import DatePickerOverlay from '../../components/DatePickerOverlay/DatePickerOverlay';
import PageTopBar from '../../components/Topbar/PageTopBar';
import { ELocalStorageKeys } from '../../constants/enums';
import { AuthSliceState } from '../../store/store.types';
import { serializeDate } from '../../utils/formattingUtil';
import {
  handleDateRangeApplied,
  handleSameDateRangeApplied
} from '../../utils/getDateRange';
import { localStorageUtil } from '../../utils/localStorageUtil';

import { EStatisticType } from './StatisticsView.types';

import './style.scss';
import 'react-toastify/dist/ReactToastify.css';

const day = dayjs();
const daytz = dayjs.extend(timezone);

const statsToLoad = [
  EMetric.TOTAL_REVENUE,
  EMetric.TOTAL_REVENUE_WITHOUT_TAX,
  EMetric.DEPOSITS,
  EMetric.ARPPU,
  EMetric.AVERAGE_DEPOSITS_PER_DEPOSITOR,
  EMetric.DEPOSITORS,
  EMetric.ARPU,
  EMetric.ACTIVE_USERS,
  EMetric.SESSIONS,
  EMetric.DAU
  // EMetric.CONVERSION_RATE
];

const trendsToLoad = [
  EMetric.TOTAL_REVENUE,
  EMetric.DEPOSITS,
  EMetric.ARPPU,
  EMetric.AVERAGE_DEPOSITS_PER_DEPOSITOR,
  EMetric.DEPOSITORS,
  EMetric.ARPU,
  EMetric.ACTIVE_USERS,
  EMetric.SESSIONS,
  EMetric.DAU
];

const StatisticsView = () => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [rangeDates, setRangeDates] = useState([day.subtract(7, 'day'), day]);
  const [formattedRangeDates, setFormattedRangeDates] = useState<
    string[] | null[]
  >([null, null]);
  const [, setPreviousFormattedRangeDates] = useState<string[] | null[]>([
    null,
    null
  ]);
  const [isSingleDay, setIsSingleDay] = useState(false);
  const [, setIsToday] = useState(false);
  const [, setHours] = useState<string[]>([]);

  useEffect(() => {
    const currentDate = dayjs().format('YYYY-MM-DD');
    setIsToday(currentDate === rangeDates[1].format('YYYY-MM-DD'));
  }, [rangeDates]);

  const currentPublisherId =
    useSelector(
      ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
    ) || undefined;

  const analytics = useAnalytics(
    formattedRangeDates[0] || day.subtract(7, 'day').toISOString(),
    formattedRangeDates[1] || day.toISOString(),
    statsToLoad,
    trendsToLoad,
    currentPublisherId
  );

  useEffect(() => {
    const currentTz = daytz.tz().format('Z');
    const from = dayjs().subtract(7, 'day').hour(0).minute(0).second(0);
    const to = dayjs().hour(23).minute(59).second(59);
    setRangeDates([from, to]);
    setFormattedRangeDates([
      from.add(parseInt(currentTz), 'hours').toISOString(),
      to.add(parseInt(currentTz), 'hours').toISOString()
    ]);
  }, []);

  const publisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );

  useEffect(() => {
    if (publisherId) {
      analytics.getAnalytics.refetch();
      analytics.getTrends.refetch();
    }
  }, [publisherId]);

  const calculatedConversionRate = (data: any, previous = false) => {
    const sessions =
      data.find((a: any) => a.name === EMetric.SESSIONS)![
        previous ? 'previousValue' : 'currentValue'
      ]! || 0;
    const deposits =
      analytics.getAnalytics.data!.find(
        (a: any) => a.name === EMetric.DEPOSITS
      )![previous ? 'previousValue' : 'currentValue']! || 0;
    if (Number.isFinite(deposits / sessions))
      return (deposits / sessions) * 100;
    return 0;
  };

  const calculatedConversionRateChangePercent = (data: any) => {
    const newSessions =
      data.find((a: any) => a.name === EMetric.SESSIONS)!['currentValue']! || 0;
    const newDeposits =
      analytics.getAnalytics.data!.find(
        (a: any) => a.name === EMetric.DEPOSITS
      )!['currentValue']! || 0;
    const oldSessions =
      data.find((a: any) => a.name === EMetric.SESSIONS)!['previousValue']! ||
      0;
    const oldDeposits =
      analytics.getAnalytics.data!.find(
        (a: any) => a.name === EMetric.DEPOSITS
      )!['previousValue']! || 0;
    if (!Number.isFinite(newDeposits / newSessions)) return 0;
    if (!Number.isFinite(oldDeposits / oldSessions)) return 0;
    return Number.isFinite(
      ((newDeposits / newSessions) * 100) /
        ((oldDeposits / oldSessions) * 100) -
        1
    )
      ? ((newDeposits / newSessions) * 100) /
          ((oldDeposits / oldSessions) * 100) -
          1
      : 0;
  };

  return (
    <AcViewWrapper header={<PageTopBar disable={false} headline="Analytics" />}>
      <AcContentWrapper>
        <DatePickerOverlay
          isOpen={isDatePickerOpen}
          setIsOpen={setIsDatePickerOpen}
          onApply={(range: any, hours: any) => {
            setHours(hours);
            setIsSingleDay(!!hours);
            if (!hours) {
              handleDateRangeApplied({
                range,
                setRangeDates,
                setFormattedRangeDates,
                setPreviousFormattedRangeDates
              });
              return;
            }
            handleSameDateRangeApplied({
              range,
              hours,
              setRangeDates,
              setFormattedRangeDates,
              setPreviousFormattedRangeDates
            });
          }}
        />
        <Stack direction="column">
          <Stack direction="column" justifyContent="center" p={'16px 0'}>
            <OutlinedInput
              size="small"
              sx={{
                width: 256,
                color: 'black',
                cursor: 'pointer !important'
              }}
              onClick={() => setIsDatePickerOpen(true)}
              id="outlined-adornment-weight"
              startAdornment={
                <InputAdornment position="start">
                  <CalendarMonthIcon />
                </InputAdornment>
              }
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                'aria-label': 'weight'
              }}
              value={`${rangeDates[0].format(
                'DD/MM/YYYY'
              )} - ${rangeDates[1].format('DD/MM/YYYY')}`}
            />
          </Stack>
          <Grid className="statBoxes" container spacing={2}>
            {statsToLoad.map((v, i) => {
              if (v === EMetric.DAU && isSingleDay) return null;
              return (
                <Grid lg={4} sm={6} item key={v}>
                  <AcStatBox
                    isLoading={analytics.getAnalytics.isLoading}
                    name={metricDictionary[v].title}
                    currentValue={
                      !analytics.getAnalytics.isLoading
                        ? analytics.getAnalytics.data!.find(
                            (a: any) => a.name === v
                          )!.currentValue
                        : undefined
                    }
                    type={
                      v === EMetric.ARPPU || v === EMetric.ARPU
                        ? EStatisticType.AMOUNT_AVERAGE
                        : v === EMetric.DAU ||
                            v === EMetric.AVERAGE_DEPOSITS_PER_DEPOSITOR
                          ? EStatisticType.PRECISE_NUMBER
                          : !analytics.getAnalytics.isLoading
                            ? analytics.getAnalytics.data!.find(
                                (a: any) => a.name === v
                              )!.type
                            : EStatisticType.CURRENCY
                    }
                    changePercent={
                      !analytics.getAnalytics.isLoading
                        ? analytics.getAnalytics.data!.find(
                            (a: any) => a.name === v
                          )!.changePercentage
                        : 0
                    }
                    metricType={v}
                  />
                </Grid>
              );
            })}
            <Grid lg={4} sm={6} item key={EMetric.CONVERSION_RATE}>
              <AcStatBox
                isLoading={analytics.getAnalytics.isLoading}
                name={metricDictionary[EMetric.CONVERSION_RATE].title}
                currentValue={
                  !analytics.getAnalytics.isLoading
                    ? calculatedConversionRate(analytics.getAnalytics.data!)
                    : 0
                }
                type={EStatisticType.PERCENTAGE}
                changePercent={
                  !analytics.getAnalytics.isLoading
                    ? calculatedConversionRateChangePercent(
                        analytics.getAnalytics.data!
                      )
                    : 0
                }
              />
            </Grid>
          </Grid>

          <Grid className="statBoxes" container spacing={2} paddingTop={4}>
            {trendsToLoad.map((v, i) => {
              if (v === EMetric.ACTIVE_USERS && !isSingleDay) return null;
              if (v === EMetric.DAU && isSingleDay) return null;
              return (
                <Grid lg={6} sm={6} item key={v}>
                  <AcChartBox
                    isSingleDay={isSingleDay}
                    isLoading={analytics.getTrends.isLoading}
                    name={
                      v === EMetric.DAU
                        ? 'Daily Active Users'
                        : metricDictionary[v].title
                    }
                    values={
                      !analytics.getTrends.isLoading
                        ? analytics.getTrends.data!.find(
                            (a: any) => a.name === v
                          )?.values
                        : []
                    }
                    type={
                      !analytics.getTrends.isLoading
                        ? analytics.getTrends.data!.find(
                            (a: any) => a.name === v
                          )?.type
                        : EStatisticType.CURRENCY
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      </AcContentWrapper>
    </AcViewWrapper>
  );
};

export default StatisticsView;
