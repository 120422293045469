import React from 'react';

import { NavLink, To } from 'react-router-dom';

const AcLink = ({
  children,
  to,
  isInternalLink = true
}: {
  children: React.ReactNode;
  to: To;
  isInternalLink?: boolean;
}) => {
  return (
    <NavLink to={to} state={{ isInternalLink }}>
      {children}
    </NavLink>
  );
};

export default AcLink;
