import { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { TabContext, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import { localStorageUtil } from 'utils/localStorageUtil';

import { EPublisherSolutionModel, User } from '../../common/contracts';
import { NEW_VERSION } from '../../constants/constants';
import { ELocalStorageKeys } from '../../constants/enums';
import AcContentWrapper from '../AcContentWrapper/AcContentWrapper';
import AcTabs from '../AcTabs/AcTabs';
import AcViewWrapper from '../AcViewWrapper/AcViewWrapper';
import PageTopBar from '../Topbar/PageTopBar';

import FreeOrdersTable from './FreeOrdersTable/FreeOrdersTable';
import OrdersTable from './OrdersTable/OrdersTable';

import './style.scss';

enum EOrdersState {
  ORDERS = 'orders',
  FREE_ORDERS = 'freeOrders'
}

const OrdersTableWrapper = () => {
  const isCheckoutUser =
    localStorageUtil.get(ELocalStorageKeys.PUBLISHER_SOLUTION_MODEL) ===
    EPublisherSolutionModel.CHECKOUT;
  const versionDetails = localStorageUtil.getAny<User>(
    ELocalStorageKeys.USER_DETAILS
  )?.version;
  const navigate = useNavigate();
  const location = useLocation();
  const basePath = location.pathname.split('/orders')[0];
  const currentTab = location.pathname.includes('/free')
    ? EOrdersState.FREE_ORDERS
    : EOrdersState.ORDERS;
  const [tab, setTab] = useState(currentTab);
  const isFreeOrdersTabShown =
    versionDetails === NEW_VERSION || !isCheckoutUser;

  const handleTabChange = (
    event: any,
    newValue: EOrdersState | ((prevState: EOrdersState) => EOrdersState)
  ) => {
    setTab(newValue);
    const newPath =
      newValue === EOrdersState.ORDERS
        ? `${basePath}/orders`
        : `${basePath}/orders/free`;
    navigate(newPath, { state: { isInternalLink: true } });
  };

  useEffect(() => {
    if (currentTab !== tab) {
      setTab(currentTab);
    }
  }, [currentTab, tab]);

  return (
    <AcViewWrapper
      header={
        <>
          <PageTopBar withTabsDesign={true} headline="Orders" />
          <Box pl={'3rem'} pr={'3rem'}>
            <AcTabs value={tab} onChange={handleTabChange}>
              <Tab label="Orders" value={EOrdersState.ORDERS} />
              {isFreeOrdersTabShown && (
                <Tab label="Free Orders" value={EOrdersState.FREE_ORDERS} />
              )}
            </AcTabs>
          </Box>
        </>
      }
    >
      <AcContentWrapper>
        <TabContext value={tab}>
          <TabPanel value={EOrdersState.ORDERS} sx={{ padding: 0 }}>
            <OrdersTable />
          </TabPanel>
          <TabPanel value={EOrdersState.FREE_ORDERS} sx={{ padding: 0 }}>
            <FreeOrdersTable />
          </TabPanel>
        </TabContext>
      </AcContentWrapper>
    </AcViewWrapper>
  );
};

export default OrdersTableWrapper;
