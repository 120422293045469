import { LoginButton } from '@appcharge/shared-ui';

interface IFacebookLoginProps {
  translations: Record<string, string>;
}

export const FacebookLogin: React.FC<IFacebookLoginProps> = ({
  translations
}) => {
  return (
    <LoginButton
      variant="facebook"
      callback={() => {}}
      text={translations?.signInWithFacebook || 'Sign in with Facebook'}
    >
      <img
        src="https://media.appcharge.com/defaults/facebook_icon_white.svg"
        alt="facebook icon"
      />
    </LoginButton>
  );
};
