import { EStyleProperty } from '../constants/enums';

import { getConvertedFontWeight } from './getConvertedFontWeight';

export const getStyledSelectItem = (
  value: string,
  styleProperty: EStyleProperty
) => {
  const newValue = getConvertedFontWeight(value);
  return (
    <span
      style={{
        [styleProperty]: [newValue || value],
        textTransform: 'capitalize'
      }}
    >
      {value}
    </span>
  );
};
