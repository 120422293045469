import { useState } from 'react';

import { Grid, Stack } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { UpdateInfoProps } from '../../views/Profile/ProfileView.types';
import AcCard from '../AcCard/AcCard';
import AcInput from '../AcInput/AcInput';
import ActionButton from '../ActionButton/ActionButton';

import './style.scss';

const PersonalInfo = ({ userData, updateInfo }: UpdateInfoProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  let updateInfoSchema = yup.object().shape({
    name: yup.string().required()
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    submitForm,
    dirty,
    resetForm,
    isValid
  } = useFormik({
    initialValues: {
      name: userData?.name || ''
    },
    validationSchema: updateInfoSchema,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      updateInfo(
        values,
        () => {
          setIsSubmitting(false);
          resetForm({ values: { name: values.name } });
        },
        () => setIsSubmitting(false)
      );
    }
  });

  return (
    <Grid container className="formContent">
      <Grid item xs={5} className="formContent-minWIdth">
        <Stack>
          <AcCard stackContainer={false} title="General">
            <Grid container rowSpacing={2} columnSpacing={2}>
              <Grid className="formContent-item" item xs={12} sm={6} md={6}>
                <AcInput
                  header="Name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && !!errors.name}
                />
              </Grid>
              <Grid className="formContent-item" item xs={12} sm={6} md={6}>
                <AcInput
                  header="Email"
                  name="email"
                  value={userData.email}
                  disabled
                />
              </Grid>
            </Grid>
          </AcCard>
        </Stack>
        <Grid item mt={3} xs={12}>
          <ActionButton
            text="Update"
            variant="outlined"
            onClick={submitForm}
            disabled={!isValid || !dirty || isSubmitting}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PersonalInfo;
