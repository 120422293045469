export enum GradientDirection {
  TopToBottom = 'to bottom',
  BottomToTop = 'to top',
  LeftToRight = 'to right',
  RightToLeft = 'to left',
  TopLeftToBottomRight = 'to bottom right',
  BottomRightToTopLeft = 'to top left',
  TopRightToBottomLeft = 'to bottom left',
  BottomLeftToTopRight = 'to top right'
}
