import { StyledLoginTermsBlock } from './style';

interface ILoginTermsBlockProps {
  textColor?: string;
  translations: any;
}
export const LoginTermsBlock: React.FC<ILoginTermsBlockProps> = ({
  textColor = 'black',
  translations
}) => {
  return (
    <StyledLoginTermsBlock textColor={textColor} className={'login-terms-text'}>
      <span className="terms-wrapper">
        {translations.byLoggingIn}{' '}
        <span onClick={() => {}}>{translations.privacyPolicy}</span>{' '}
        {translations.and}{' '}
        <span onClick={() => {}}>{translations.termsAndConditions}</span>
      </span>
    </StyledLoginTermsBlock>
  );
};
