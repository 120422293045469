import { useEffect, useRef, useState } from 'react';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  InputAdornment,
  MenuItem,
  Popover,
  Select,
  Stack,
  TextField,
  TextFieldProps,
  Tooltip
} from '@mui/material';

import { GradientDirection } from './AcSolidInput.types';

const PICK_TIMEOUT = 180;

// Change colors to hex
const standardizeColor = (color: string) => {
  let ctx: CanvasRenderingContext2D | null = document
    .createElement('canvas')
    .getContext('2d');
  if (ctx == null) {
    return '';
  } else {
    ctx.fillStyle = color;
    return ctx.fillStyle;
  }
};

const AcSolidInput = ({
  header = '',
  tooltip = '',
  textarea = false,
  onGradientOrSolidChange = () => {},
  defaultValue = '#ffffff',
  setValue,
  outputAsSingleColor = false,
  roundedBorders = false,
  imgWidth = '32px',
  imgHeight = '32px',
  ...props
}: TextFieldProps & {
  header?: string;
  tooltip?: string;
  textarea?: boolean;
  onGradientOrSolidChange?: (value: any) => any;
  onlySolid?: boolean;
  defaultValue?: string;
  setValue: any;
  outputAsSingleColor?: boolean;
  roundedBorders?: boolean;
  imgWidth?: string;
  imgHeight?: string;
}) => {
  const textInputRef = useRef(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState(defaultValue);

  const changeHandler = (value: string) => {
    setTimeout(() => {
      setValue(props.name, value);
      setCurrentValue(value);
    }, PICK_TIMEOUT);
  };

  const whiteColors: string[] = ['#ffffff', 'white'];
  const containsWhite: boolean = whiteColors.includes(
    defaultValue.toLowerCase()
  );

  return (
    <div>
      <Stack
        className="gradientInput-container formContent"
        direction="column"
        style={{ position: 'relative', marginBottom: 1.5 }}
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={'5px'}
          marginBottom={'4px'}
          className="formContent-input-fieldTitle"
        >
          {header && (
            <Tooltip arrow title={tooltip}>
              <h3>{header}</h3>
            </Tooltip>
          )}
        </Stack>
        <TextField
          {...props}
          variant={props.variant ?? 'outlined'}
          sx={{ width: '100%', cursor: 'pointer' }}
          value={currentValue.toUpperCase()}
          multiline={!!textarea}
          rows={textarea ? 2 : undefined}
          onClick={() => setIsPopoverOpen(true)}
          ref={textInputRef}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <div
                  style={{
                    background: currentValue,
                    border: containsWhite ? '1px solid grey' : '',
                    borderRadius: roundedBorders ? '4px' : 0,
                    cursor: 'pointer',
                    width: imgWidth,
                    height: imgHeight
                  }}
                ></div>
              </InputAdornment>
            )
          }}
          className="formContent-input-inputField formContent-input-gradientInput"
        />
        <Popover
          id={'gradient-popover'}
          open={isPopoverOpen}
          anchorEl={textInputRef.current}
          onClose={() => setIsPopoverOpen(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <Stack
            direction="row"
            style={{ minWidth: '360px', padding: '1em' }}
            gap={1}
          >
            <TextField
              type="color"
              style={{
                minWidth: '100%',
                width: '100%',
                minHeight: '37px'
              }}
              value={currentValue.toUpperCase()}
              onChange={(e) => {
                changeHandler(e.target.value);
              }}
              size="small"
              sx={{
                '.MuiInputBase-root': {
                  height: '100%'
                }
              }}
            />
          </Stack>
        </Popover>
      </Stack>
    </div>
  );
};

export default AcSolidInput;
