import { useQuery } from '@tanstack/react-query';

import { User } from '../common/contracts';
import { NEW_VERSION } from '../constants/constants';
import { ELocalStorageKeys, EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';
import { localStorageUtil } from '../utils/localStorageUtil';

import { EApiRoutes, fetchConfig, getApiRouteOrMock } from './api.utils';

export default function useSegments(currentPublisherId: string | null) {
  const axios = useAxios();

  const versionDetails = localStorageUtil.getAny<User>(
    ELocalStorageKeys.USER_DETAILS
  )?.version;

  const getSegments = useQuery({
    ...fetchConfig.list,
    queryKey: [EQueryKeys.SEGMENTS],
    queryFn: async () => {
      return await axios.get(getApiRouteOrMock(EApiRoutes.SEGMENTS));
    },
    enabled: versionDetails === NEW_VERSION ? !!currentPublisherId : true
  });

  return {
    getSegments
  };
}
