import { useMutation, useQuery } from '@tanstack/react-query';

import { User } from '../common/contracts';
import { NEW_VERSION } from '../constants/constants';
import { ELocalStorageKeys, EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';
import { localStorageUtil } from '../utils/localStorageUtil';

import { EApiRoutes, fetchConfig, getApiRouteOrMock } from './api.utils';

export default function useImages(currentPublisherId: string | null) {
  const axios = useAxios();
  const versionDetails = localStorageUtil.getAny<User>(
    ELocalStorageKeys.USER_DETAILS
  )?.version;

  const getImages = useQuery({
    ...fetchConfig.list,
    queryKey: [EQueryKeys.IMAGES],
    queryFn: async () => {
      return await axios.get(getApiRouteOrMock(EApiRoutes.IMAGES));
    },
    enabled: versionDetails === NEW_VERSION ? !!currentPublisherId : true
  });

  const deleteImage = useMutation(async (imageId: string) => {
    return await axios.del(
      `${getApiRouteOrMock(EApiRoutes.DELETE_IMAGES)}/${imageId}`
    );
  });

  const addImage = useMutation(async (newImage: FormData) => {
    return await axios.post(getApiRouteOrMock(EApiRoutes.POST_IMAGE), newImage);
  });

  return {
    addImage,
    getImages,
    deleteImage
  };
}
