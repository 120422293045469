import { useEffect } from 'react';

import { Grid, SelectChangeEvent, Stack } from '@mui/material';
import { permissionsUtil } from 'utils/permissionsUtil';
import {
  refreshEveryOptions,
  roundedHoursOptions
} from 'utils/selectOptionsUtils';

import AcCard from 'components/AcCard/AcCard';
import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';

import { formatCronUtils } from '../utils';

const PermanentOffer = ({
  formikProps,
  startHour,
  setStartHour,
  intervalCron,
  setIntervalCron
}: any) => {
  const { values, handleChange, handleBlur, touched, errors, setFieldValue } =
    formikProps;

  useEffect(() => {
    if (values.schedule?.interval) {
      const initialStartHour = formatCronUtils.parseCronToHour(
        values?.schedule.interval
      );
      setStartHour(initialStartHour);
    }
  }, [values.schedule?.interval]);

  useEffect(() => {
    if (intervalCron && values?.schedule?.permanent) {
      setFieldValue('schedule', {
        ...values.schedule,
        interval: intervalCron
      });
    }
  }, [intervalCron]);

  const handleSelectHour = (e: SelectChangeEvent<unknown>) => {
    const selectedHour = e.target.value as string;
    setStartHour(selectedHour);
    if (selectedHour) {
      setIntervalCron(formatCronUtils.generateCronString(selectedHour));
    }
  };

  return (
    <Stack
      spacing={2}
      sx={{
        marginTop: '2rem'
      }}
    >
      <AcCard
        stackContainer={false}
        title="Permanent Offer"
        description="Permanent events won’t have an end date"
      >
        <Grid container columnSpacing={3}>
          <Grid item xs={3}>
            <AcInput
              header="Availability"
              name="playerAvailability"
              value={values.playerAvailability || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              type="number"
              error={
                touched.playerAvailability && Boolean(errors.playerAvailability)
              }
              helperText={
                touched.playerAvailability
                  ? errors.playerAvailability?.toString()
                  : ''
              }
            />
          </Grid>
          <Grid item xs={3}>
            <AcSelect
              header="Refresh availability every"
              name="refreshEvery"
              value="Day"
              items={refreshEveryOptions}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled
            />
          </Grid>
          {permissionsUtil.isSuperAdmin() ? (
            <Grid item xs={1}>
              <AcInput
                header="Day Start Hour (UTC)"
                name="startHour"
                type="time"
                value={startHour}
                onChange={(e) => {
                  const selectedHour = e.target.value;
                  setStartHour(selectedHour);
                  setIntervalCron(
                    formatCronUtils.generateCronString(selectedHour)
                  );
                }}
                error={
                  touched.playerAvailability &&
                  Boolean(errors.playerAvailability)
                }
              />
            </Grid>
          ) : (
            <Grid item xs={3}>
              <AcSelect
                header="Day Start Hour (UTC)"
                name="startHour"
                value={startHour}
                items={roundedHoursOptions}
                onChange={(e: SelectChangeEvent<unknown>) =>
                  handleSelectHour(e)
                }
                disabled={
                  touched.playerAvailability &&
                  Boolean(errors.playerAvailability)
                }
              />
            </Grid>
          )}
        </Grid>
      </AcCard>
    </Stack>
  );
};

export default PermanentOffer;
