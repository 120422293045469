import { Grid } from '@mui/material';

import AcInput from '../../../components/AcInput/AcInput';
import AcSelect from '../../../components/AcSelect/AcSelect';
import { EAcSelectItemRenderType } from '../../../components/AcSelect/AcSelect.types';
import { Product } from '../../../components/ProductsForm/ProductsForm.types';

const ProductSelector = ({
  currentProduct,
  setCurrentProduct,
  handleBlur,
  handleQuantityChange,
  products,
  productsData,
  isLoadingProducts,
  touched,
  errors
}: any) => {
  const availableProducts =
    !isLoadingProducts && productsData
      ? productsData.result

          .map((product: Product) => {
            if (!products.find((p: any) => p.productId === product.productId)) {
              return {
                url: product.images?.[0]?.url,
                name: product.name,
                content: product.name,
                key: product.productId,
                value: product.productId
              };
            }
            return undefined;
          })
          .filter((v: any) => v !== undefined)
      : [];

  return (
    <>
      <Grid item xs={6}>
        <AcSelect
          header="Name"
          name="name"
          value={currentProduct?.productId || ''}
          onChange={(e) => {
            setCurrentProduct({
              ...currentProduct,
              productId: e.target.value || '',
              quantity: currentProduct?.quantity || 0
            });
          }}
          onBlur={handleBlur}
          renderType={EAcSelectItemRenderType.IMAGE}
          items={availableProducts}
          disabled={products.length >= 1 ? true : false}
        />
      </Grid>
      <Grid item xs={6}>
        <AcInput
          header="Quantity"
          name="quantity"
          value={String(currentProduct?.quantity)}
          type="number"
          inputProps={{ min: 0, disabled: products.length >= 1 ? true : false }}
          onChange={handleQuantityChange}
          error={touched.quantity && Boolean(errors.quantity)}
          helperText={touched.quantity ? errors.quantity?.toString() : ''}
        />
      </Grid>
    </>
  );
};

export default ProductSelector;
