export interface LoginData {
  userName?: string;
  password?: string;
  loginToken?: string;
  rememberMe?: boolean;
}

export enum ELoginAuthMethod {
  USERNAME_PASSWORD = 'USERNAME_PASSWORD',
  GOOGLE = 'GOOGLE'
}

export interface LoginNotificationData {
  msg: string;
  type: 'success' | 'error';
}

export type ModalState =
  | 'login'
  | 'resetPassword'
  | 'forgetPassword'
  | 'finishAccount';

export interface LoginPayload {
  userId: string;
  authJwt?: string;
  currentPublisherId?: string;
}
