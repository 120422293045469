import LanguagesListSection
    from 'views/Localization/components/LanguagesListSection/LanguagesListSection';
import ExportTranslationsCSVSection
    from 'views/Localization/components/TranslationsCSVSection//ExportTranslationsCSVSection';
import ImportTranslationsCSVSection
    from 'views/Localization/components/TranslationsCSVSection/ImportTranslationsCSVSection';

import AcContentWrapper from 'components/AcContentWrapper/AcContentWrapper';
import AcViewWrapper from 'components/AcViewWrapper/AcViewWrapper';
import PageTopBar from 'components/Topbar/PageTopBar';

const LocalizationView = () => {
    return (
        <AcViewWrapper
            header={<PageTopBar disable={false} headline="Localization" buttons={[]} />}
        >
            <AcContentWrapper>
                <LanguagesListSection />
                <ExportTranslationsCSVSection />
                <ImportTranslationsCSVSection />
            </AcContentWrapper>
        </AcViewWrapper>
    )
}

export default LocalizationView;
