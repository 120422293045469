import { Route, Routes } from 'react-router-dom';

import CouponsTable from './CouponsTable';
import { CouponsForm } from './Form/CouponsForm';

import '../../views/view.scss';

const CouponsView = () => {
  return (
    <Routes>
      <Route path="/" element={<CouponsTable />} />
      <Route path="/form" element={<CouponsForm />} />
      <Route path="/form/:couponId" element={<CouponsForm edit={true} />} />
    </Routes>
  );
};

export default CouponsView;
