import React from 'react';

import AcContentWrapper from 'components/AcContentWrapper/AcContentWrapper';
import DialogModal from 'components/Dialog/Dialog';
import EditLanguageForm from './components/EditLanguageForm/EditLanguageForm';

import { EditLanguageModalProps } from './EditLanguageModal.types';

import './style.scss';

const EditLanguageModal = ({
  currentLanguage,
  isOpen,
  onClose,
  onSave
}: EditLanguageModalProps) => (
  <DialogModal
    isOpen={isOpen}
    closeButton={true}
    headline={'Edit Language Display Name'}
    width={'392px'}
    withDivider={false}
    content={
      <AcContentWrapper className="editLanguageContent">
          <EditLanguageForm
              selectedLanguage={currentLanguage}
              onSave={onSave}
          />
      </AcContentWrapper>
    }
    closeDialog={onClose}
  />
);

export default EditLanguageModal;
