import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ScheduleIcon from '@mui/icons-material/Schedule';

import {
  EStatusLabel,
  StatusLabelProps
} from '../../design-system/StatusLabel/types';

export interface Interval {
  startDate: string;
  endDate: string;
}

export enum EReportStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  CREATED = 'CREATED',
  FAILED = 'FAILED'
}

export enum EPayoutStatus {
  SUCCESS = 'SUCCESS'
}

export const financialReportEnumStatusMap: Record<
  EReportStatus,
  StatusLabelProps
> = {
  [EReportStatus.IN_PROGRESS]: {
    text: 'Processing',
    status: EStatusLabel.PENDING,
    prefixIcon: <ScheduleIcon />
  },
  [EReportStatus.CREATED]: {
    text: 'Done',
    status: EStatusLabel.SUCCESS,
    prefixIcon: <CheckCircleOutlineIcon />
  },
  [EReportStatus.FAILED]: { text: 'Failed', status: EStatusLabel.FAILED }
};

export const payoutEnumStatusMap: Record<EPayoutStatus, StatusLabelProps> = {
  [EPayoutStatus.SUCCESS]: {
    text: 'Success',
    status: EStatusLabel.SUCCESS,
    prefixIcon: <CheckCircleOutlineIcon />
  }
};

export interface Report {
  reportId: string;
  filename: string;
  createdBy: string;
  status: EReportStatus;
  interval: Interval;
  createdAt: Date;
  updatedAt: Date;
}

export interface Payout {
  transferDate: Date;
  amount: number;
  currency: string;
  period: Interval;
  status: EPayoutStatus;
  reference: string;
  fileName: string;
}

export interface PayoutRequest {
  transferDate: Date;
  amount: number;
  currency: string;
  period: Interval;
  status: EPayoutStatus;
  reference: string;
  payoutFile: File;
}
