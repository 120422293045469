import { Stack } from '@mui/material';

export default function useAcSelect() {
  const imageRenderFunction = (picture: any, imgHeight?: string) => {
    return (
      <Stack
        direction="row"
        spacing={2}
        overflow="hidden"
        className="select-img-block formContent-imageSelected"
      >
        <img
          alt={picture.name}
          src={picture.url}
          style={{
            height: `${imgHeight}px` || '22px'
          }}
        />
        {picture.name}
      </Stack>
    );
  };

  return { imageRenderFunction };
}
