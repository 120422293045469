import { Box, Stack } from '@mui/material';

import ActionButton from '../ActionButton/ActionButton';

import { FirstActionModalProps } from './FirstActionModal.types';

import './style.scss';

const FirstActionModal = ({
  actionButtonIcon,
  headline,
  text,
  actionButtonFunc,
  actionButtonText,
  actionButtonDisabled,
  actionButtonTooltip,
  height
}: FirstActionModalProps) => {
  return (
    <Stack
      width="100%"
      height={height ?? 'calc(100vh - 112px)'}
      justifyContent="center"
      alignItems="center"
      className="firstActionModalContainer"
    >
      <Box className="firstActionModal">
        <h3>{headline}</h3>
        <p>{text}</p>
        {actionButtonText && (
          <ActionButton
            style={{
              marginTop: '1rem'
            }}
            onClick={actionButtonFunc}
            icon={actionButtonIcon}
            text={actionButtonText}
            disabled={actionButtonDisabled}
            tooltip={actionButtonTooltip}
            fullWidth
          />
        )}
      </Box>
    </Stack>
  );
};

export default FirstActionModal;
