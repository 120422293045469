import Decimal from 'decimal.js';

import { ConvertedPriceParams } from '../common/contracts';

export const getConvertedPrice = (params: ConvertedPriceParams) => {
  return (
    <>
      {params.row.productsSequence.map(
        ({ priceInUsdCents }: { priceInUsdCents: number }, index) => {
          if (priceInUsdCents === 0) {
            return <span key={index}>Free</span>;
          } else {
            const price = new Decimal(priceInUsdCents)
              .dividedBy(100)
              .toFixed(2);
            return <span key={index}>{`$${price}`}</span>;
          }
        }
      )}
    </>
  );
};
