import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Divider, Stack } from '@mui/material';
import { GridActionsCellItem, GridRenderCellParams } from '@mui/x-data-grid';
import {
  ExtendedAppearanceStatus,
  ScheduleTimeFrame,
  TimeFrameTableData
} from 'common/contracts';
import { DATE_TIME_FORMAT_UTC } from 'constants/constants';
import {
  EAppearanceStatus,
  ELocalStorageKeys,
  EPendingAppearanceStatus
} from 'constants/enums';
import dayjs from 'dayjs';
import { StatusLabel } from 'design-system/StatusLabel/StatusLabel';
import { EStatusLabel } from 'design-system/StatusLabel/types';

import AcCard from 'components/AcCard/AcCard';
import AcDateTimePicker from 'components/AcDateTimePicker/AcDateTimePicker';
import AcInput from 'components/AcInput/AcInput';
import DataTable from 'components/DataTable/DataTable';

import { determineStatus } from '../utils';

type DateRange = [dayjs.Dayjs | null, dayjs.Dayjs | null];

const ScheduledOffer = ({
  formikProps,
  editRangeDatesValues,
  setEditRangeDatesValues,
  intervalCron,
  appearancesTableData,
  notes,
  setNotes,
  dateRange,
  setDateRange,
  resetScheduleValues
}: any) => {
  const { values, handleChange, handleBlur, touched, errors, setFieldValue } =
    formikProps;

  const isOverlapping = (
    newStartTime: dayjs.Dayjs,
    newEndTime: dayjs.Dayjs
  ) => {
    return values?.schedule?.timeFrames.some((timeFrame: ScheduleTimeFrame) => {
      const existingStart = new Date(timeFrame.startTime).getTime();
      const existingEnd = new Date(timeFrame.endTime).getTime();

      return (
        (newStartTime.valueOf() >= existingStart &&
          newStartTime.valueOf() <= existingEnd) ||
        (newEndTime.valueOf() >= existingStart &&
          newEndTime.valueOf() <= existingEnd) ||
        (newStartTime.valueOf() <= existingStart &&
          newEndTime.valueOf() >= existingEnd)
      );
    });
  };

  const handleApply = (range: DateRange, notes?: string) => {
    const [fromUtcDate, toUtcDate] = range;

    if (fromUtcDate && toUtcDate) {
      const newTimeFrame: ScheduleTimeFrame = {
        startTime: new Date(fromUtcDate.toISOString()), // UTC start date
        endTime: new Date(toUtcDate.toISOString()), // UTC end date
        notes
      };

      if (editRangeDatesValues) {
        const [editStartTime, editEndTime] = editRangeDatesValues;

        const updatedTimeFrames = values?.schedule?.timeFrames.map(
          (timeFrame: ScheduleTimeFrame) => {
            const currentStartTimestamp = new Date(
              timeFrame.startTime
            ).getTime();
            const currentEndTimestamp = new Date(timeFrame.endTime).getTime();

            return currentStartTimestamp ===
              new Date(editStartTime).getTime() &&
              currentEndTimestamp === new Date(editEndTime).getTime()
              ? newTimeFrame
              : timeFrame;
          }
        );
        setFieldValue('schedule', {
          permanent: values?.schedule.permanent,
          timeFrames: updatedTimeFrames,
          ...(intervalCron &&
            values?.schedule?.permanent && { interval: intervalCron })
        });
      } else {
        setEditRangeDatesValues(null);
        setFieldValue('schedule', {
          permanent: values?.schedule.permanent,
          timeFrames: [...values?.schedule?.timeFrames, newTimeFrame],
          ...(intervalCron &&
            values?.schedule?.permanent && { interval: intervalCron })
        });
      }
    } else {
      console.error('Invalid date range provided: Start or End date is null.');
    }
  };

  const columns = [
    {
      field: 'startTime',
      headerName: 'Start',
      disableReorder: true,
      minWidth: 150,
      flex: 1,
      renderCell: (params: GridRenderCellParams<Date>) => {
        return (
          <span>
            {dayjs(params.row.startTime).utc().format(DATE_TIME_FORMAT_UTC)}
          </span>
        );
      }
    },
    {
      field: 'endTime',
      headerName: 'End',
      disableReorder: true,
      minWidth: 150,
      flex: 1,
      renderCell: (params: GridRenderCellParams<Date>) => {
        return (
          <span>
            {dayjs(params.row.endTime).utc().format(DATE_TIME_FORMAT_UTC)}
          </span>
        );
      }
    },
    {
      field: 'notes',
      headerName: 'Notes',
      disableReorder: true,
      minWidth: 150,
      flex: 1
    },
    {
      field: 'status',
      headerName: 'Status',
      disableReorder: true,
      minWidth: 150,
      flex: 1,
      renderCell: (row: GridRenderCellParams<TimeFrameTableData>) => {
        const { startTime, endTime, id } = row.row;
        const pendingSaveStatus = id.startsWith('id-');
        const status: ExtendedAppearanceStatus = pendingSaveStatus
          ? EPendingAppearanceStatus.PENDING_SAVE
          : determineStatus(new Date(startTime), new Date(endTime));
        return (
          <StatusLabel
            text={status}
            status={
              status === EAppearanceStatus.RUNNING
                ? EStatusLabel.ACTIVE
                : status === EAppearanceStatus.UPCOMING
                  ? EStatusLabel.PENDING
                  : pendingSaveStatus
                    ? EStatusLabel.CANCELED
                    : EStatusLabel.FAILED
            }
            prefixIcon={
              status === EAppearanceStatus.RUNNING ? (
                <CheckCircleOutlineIcon />
              ) : (
                <ScheduleIcon />
              )
            }
          />
        );
      },
      sortComparator: (a: any, b: any, params1: any, params2: any): number => {
        const rowA = appearancesTableData.find(
          (row: TimeFrameTableData) => row.id === params1.id
        );
        const rowB = appearancesTableData.find(
          (row: TimeFrameTableData) => row.id === params2.id
        );

        if (!rowA || !rowB) return 0;

        const startTimeA = rowA.startTime
          ? new Date(rowA.startTime)
          : new Date();
        const endTimeA = rowA.endTime ? new Date(rowA.endTime) : new Date();
        const startTimeB = rowB.startTime
          ? new Date(rowB.startTime)
          : new Date();
        const endTimeB = rowB.endTime ? new Date(rowB.endTime) : new Date();

        const statusA = determineStatus(startTimeA, endTimeA);
        const statusB = determineStatus(startTimeB, endTimeB);

        const statusOrder = {
          [EAppearanceStatus.RUNNING]: 1,
          [EAppearanceStatus.UPCOMING]: 2,
          [EAppearanceStatus.ENDED]: 3
        };

        return (statusOrder[statusA] || 0) - (statusOrder[statusB] || 0);
      }
    },
    {
      field: 'actions',
      type: 'actions',
      disableReorder: true,
      width: 50,
      getActions: (params: GridRenderCellParams<TimeFrameTableData>) => [
        <GridActionsCellItem
          icon={<EditTwoToneIcon />}
          label="Edit"
          showInMenu
          onClick={() => {
            const { startTime, endTime, notes } = params.row;
            setEditRangeDatesValues([
              new Date(startTime),
              new Date(endTime),
              notes
            ]);
          }}
        />,
        <GridActionsCellItem
          className="danger"
          icon={<DeleteOutlineTwoToneIcon className="danger" />}
          label="Delete"
          showInMenu
          onClick={() => {
            const { startTime, endTime } = params.row;
            const newTimeFrames = values?.schedule?.timeFrames?.filter(
              (timeFrame: ScheduleTimeFrame) => {
                return (
                  new Date(timeFrame.startTime).getTime() !==
                    new Date(startTime).getTime() &&
                  new Date(timeFrame.endTime).getTime() !==
                    new Date(endTime).getTime()
                );
              }
            );
            setEditRangeDatesValues(null);
            setNotes('');
            if (!newTimeFrames.length) {
              setFieldValue('schedule', {
                permanent: false,
                timeFrames: newTimeFrames
              });
            } else {
              setFieldValue('schedule', {
                permanent: values?.schedule.permanent,
                timeFrames: newTimeFrames,
                ...(intervalCron &&
                  values?.schedule?.permanent && { interval: intervalCron })
              });
            }
          }}
        />
      ]
    }
  ];

  return (
    <Stack
      spacing={2}
      sx={{
        marginTop: '2rem'
      }}
    >
      <AcCard
        stackContainer={false}
        title={editRangeDatesValues ? 'Edit appearance' : 'Scheduled offer'}
      >
        <AcInput
          header="Availability"
          name="playerAvailability"
          value={values.playerAvailability || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
          onBlur={handleBlur}
          required={values.setAsFree}
          type="number"
          error={
            touched.playerAvailability && Boolean(errors.playerAvailability)
          }
          helperText={
            touched.playerAvailability
              ? errors.playerAvailability?.toString()
              : ''
          }
          sx={{ width: '250px' }}
        />
        <Divider />
        <AcDateTimePicker
          onApply={(dateRange: DateRange, notes?: string) =>
            handleApply(dateRange, notes)
          }
          editRangeDatesValues={editRangeDatesValues}
          setEditRangeDatesValues={setEditRangeDatesValues}
          disableFuture={false}
          minDate={dayjs().utc().startOf('day')}
          applyText={editRangeDatesValues ? 'Apply' : 'Add Appearance'}
          isOverlapping={isOverlapping}
          notes={notes}
          setNotes={setNotes}
          formikProps={formikProps}
          dateRange={dateRange}
          setDateRange={setDateRange}
          resetScheduleValues={resetScheduleValues}
        />
      </AcCard>
      <Divider />
      <DataTable
        columns={columns}
        rows={appearancesTableData}
        loading={false}
        rowIdIdentifier="id"
        defaultHiddenFields={['id']}
        localStorageColumnsKey={ELocalStorageKeys.ORDERS_COLUMN_VISIBILITY}
        hideFooter
        error={false}
        initialSorting={{
          sortModel: [{ field: 'createdAt', sort: 'desc' }]
        }}
      />
    </Stack>
  );
};

export default ScheduledOffer;
