import React, { ChangeEvent, forwardRef } from 'react';

import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import {
  CircularProgress,
  Grid,
  SelectChangeEvent,
  Stack,
  Typography
} from '@mui/material';
import { FormikProps } from 'formik';

import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';

import { AssetsFormValues } from '../../AssetsView';
import { assetsCategoriesItems } from '../constants/constants';

interface UploadAssetContentProps {
  handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isUploading: boolean;
  imageId: string;
  category: string;
  handleTrimChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: FormikProps<AssetsFormValues>['handleBlur'];
  handleCategoryChange: (e: SelectChangeEvent<unknown>) => void;
}
const UploadAssetContent = forwardRef<
  HTMLInputElement,
  UploadAssetContentProps
>(
  (
    {
      handleFileChange,
      isUploading,
      imageId,
      category,
      handleTrimChange,
      handleBlur,
      handleCategoryChange
    },
    ref
  ) => {
    const inputRef = ref as React.RefObject<HTMLInputElement>;
    const file = inputRef?.current?.files?.[0];
    return (
      <Grid container spacing={2} style={{ fontFamily: 'Roboto' }}>
        <Grid item xs={12}>
          <input
            style={{ display: 'none' }}
            type="file"
            ref={ref}
            accept="image/*"
            onChange={handleFileChange}
          />
          <Stack
            height="144px"
            bgcolor="rgba(202, 203, 212, 0.2)"
            border="1px solid rgba(202, 203, 212, 1)"
            justifyContent="center"
            alignItems="center"
            borderRadius={1}
            gap={1}
            onClick={() => {
              inputRef?.current?.click();
            }}
            sx={{
              cursor: isUploading ? 'unset' : 'pointer'
            }}
          >
            {isUploading ? (
              <CircularProgress />
            ) : inputRef?.current && file ? (
              <img
                src={URL.createObjectURL(file)}
                alt="Asset"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
              />
            ) : (
              <>
                <AddPhotoAlternateOutlinedIcon
                  sx={{
                    fontSize: '64px'
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    color: 'rgba(97, 9, 255, 1)',
                    textDecoration: 'underline'
                  }}
                  color="textSecondary"
                >
                  Upload your asset image
                </Typography>
              </>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <AcInput
            header="ID"
            name="imageId"
            value={imageId}
            onChange={handleTrimChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <AcSelect
            header="Category"
            name="category"
            value={category}
            onChange={handleCategoryChange}
            onBlur={handleBlur}
            items={assetsCategoriesItems}
          />
        </Grid>
      </Grid>
    );
  }
);
export default UploadAssetContent;
