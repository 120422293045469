import { AcSeparatorProps } from './AcSeparator.types';

import './style.scss';

const AcSeparator = ({
  marginTop = 0,
  marginBottom = 0,
  dotted = false
}: AcSeparatorProps) => {
  return (
    <hr
      className={`acSeparator ${dotted && 'dotted'}`}
      style={{
        marginTop: marginTop,
        marginBottom: marginBottom
      }}
    />
  );
};

export default AcSeparator;
