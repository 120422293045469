import { useEffect } from 'react';

import { Stack } from '@mui/material';

import AcSearchInput from 'components/AcInput/AcSearchInput';

import DatePickerOverlay from '../../../DatePickerOverlay/DatePickerOverlay';

import { OrdersDatePickerProps } from './OrdersDatePicker.types';

const OrdersDatePicker = ({
  isOpen,
  setOverlayOpen,
  handleDateApply,
  formattedDate,
  startDate,
  endDate
}: OrdersDatePickerProps) => {
  useEffect(() => {
    handleDateApply([startDate, endDate], null);
  }, [startDate, endDate]);

  return (
    <>
      <DatePickerOverlay
        isOpen={isOpen}
        setIsOpen={setOverlayOpen}
        onApply={(range: any, hours: any) => handleDateApply(range, hours)}
        isHourPicker={false}
      />
      <Stack direction="column" justifyContent="center">
        <AcSearchInput
          size="small"
          header="Day & Time"
          sx={{ flexGrow: 1 }}
          onClick={() => setOverlayOpen(true)}
          id="outlined-adornment-weight"
          setValue={setOverlayOpen}
          value={formattedDate}
          isDate={true}
        />
      </Stack>
    </>
  );
};
export default OrdersDatePicker;
