import React from 'react';

import { Typography } from '@mui/material';
import { Box } from '@mui/system';

import { ErrorBoxProps } from './ErrorBox.types';

const ErrorBox = ({
  header = 'Oh no, something went wrong',
  description = 'Try refreshing the application'
}: ErrorBoxProps) => {
  return (
    <Box
      style={{
        background: 'white',
        width: 'contain',
        display: 'flex',
        padding: '2em',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        maxWidth: '450px',
        gap: '.5em',
        borderRadius: '.5em',
        color: '#26282B'
      }}
    >
      <Typography variant="h5">{header}</Typography>
      <Typography variant="subtitle1">{description}</Typography>
    </Box>
  );
};

export default ErrorBox;
