import { Route, Routes } from 'react-router-dom';

import PricingForm from './PricingForm';
import PricingTable from './PricingTable';

const PricingView = () => {
  return (
    <Routes>
      <Route path="/" element={<PricingTable />} />
      <Route path="/form" element={<PricingForm />} />
      <Route path="/form/:pricePointId" element={<PricingForm />} />
    </Routes>
  );
};

export default PricingView;
