import { Line } from 'react-chartjs-2';

import { Skeleton } from '@mui/material';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js';
import dayjs from 'dayjs';

import { EStatisticType } from '../../views/Statistics/StatisticsView.types';

import { AcChartBoxProps } from './AcChartBox.types';

import './style.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const AcChartBox = ({
  values = [],
  name,
  type = EStatisticType.CURRENCY,
  isLoading,
  isSingleDay
}: AcChartBoxProps) => {
  const options = {
    interaction: {
      intersect: false
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
        display: false
      },
      title: {
        display: false
      }
    },
    tension: 0,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value: any) {
            if (type === EStatisticType.CURRENCY) {
              return '$' + value;
            }
            return value;
          }
        }
      },
      x: {
        ticks: {
          maxTicksLimit: 10 // Set the maximum number of ticks on the x-axis to 10
        }
      }
    }
  };

  const data = {
    labels: isLoading ? [] : getLabelsFormatted(),
    datasets: isLoading
      ? []
      : [
          {
            label: name,
            data: values.map((v: any) => v.value),
            borderColor: 'rgb(114, 34, 255)',
            backgroundColor: 'rgba(114, 34, 255, 0.5)'
          }
        ]
  };

  function getLabelsFormatted(): string[] {
    const dates = values.map((v: any) => v.date);
    if (isSingleDay) {
      const formatTime = (timeString: string) => {
        const [hours, minutes] = timeString.split(':');
        return dayjs()
          .startOf('day')
          .add(Number(hours), 'hour')
          .add(Number(minutes || 0), 'minute')
          .format('h:mmA');
      };

      return dates.map(formatTime);
    } else {
      return dates;
    }
  }

  return (
    <div className="acChartBox">
      <div className="stat-name">
        {isLoading ? (
          <Skeleton animation="wave" variant="text" height={40} />
        ) : (
          <span>{name}</span>
        )}
      </div>
      <div className="chart">
        {isLoading ? (
          <Skeleton
            animation="wave"
            variant="rectangular"
            width="100%"
            height={280}
          />
        ) : (
          <Line options={options} data={data} />
        )}
      </div>
    </div>
  );
};

export default AcChartBox;
