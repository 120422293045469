import { Route, Routes } from 'react-router-dom';

import SegmentsTable from './SegmentsTable';

import '../view.scss';

const SegmentsView = () => {
  return (
    <Routes>
      <Route path="/" element={<SegmentsTable />} />
    </Routes>
  );
};

export default SegmentsView;
