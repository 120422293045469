import { StatusLabelProps } from './types';

import './style.scss';

export const StatusLabel = ({
  text = '',
  prefixIcon,
  status
}: StatusLabelProps) => {
  return (
    <div className={`status-label ${status}`}>
      {prefixIcon}
      <span className={'status-label__text'}>{text}</span>
    </div>
  );
};
