import { LoginButton } from '@appcharge/shared-ui';

interface GoogleLoginProps {
  translations: Record<string, string>;
}

export const GoogleLogin: React.FC<GoogleLoginProps> = ({ translations }) => {
  return (
    <LoginButton
      variant="google"
      callback={() => {}}
      text={translations?.signInWithGoogle || 'Sign in with Google'}
    >
      <img
        src="https://media.appcharge.com/defaults/google_icon.svg"
        alt="google icon"
      />
    </LoginButton>
  );
};
