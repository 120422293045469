import { useEffect, useMemo, useRef, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Stack } from '@mui/material';
import {
  getGridStringOperators,
  GridActionsCellItem,
  GridSortModel
} from '@mui/x-data-grid';
import usePermissions from 'api/usePermissions';
import useUsers from 'api/useUsers';
import dayjs from 'dayjs';
import { permissionsUtil } from 'utils/permissionsUtil';

import useOrders from '../../../api/useOrders';
import { EPublisherSolutionModel, User } from '../../../common/contracts';
import { DATE_TIME_FORMAT, NEW_VERSION } from '../../../constants/constants';
import {
  ELocalStorageKeys,
  ENotificationType,
  EOrderStatus,
  GridFeatureMode,
  SortingDirection,
  SortingOrderValue
} from '../../../constants/enums';
import { StatusLabel } from '../../../design-system/StatusLabel/StatusLabel';
import { useNotifications } from '../../../hooks/useNotifications';
import { usePaginationFromURL } from '../../../hooks/usePaginationFromURL';
import { isEmptyString, NullableString } from '../../../utils/isEmptyString';
import { localStorageUtil } from '../../../utils/localStorageUtil';
import { getSelectProjectOptions } from '../../../utils/projectsUtil';
import DataTable from '../../DataTable/DataTable';
import FirstActionModal from '../../FirstActionModal/FirstActionModal';
import ProjectsSelect from '../../ProjectsSelect/ProjectsSelect';
import FilterBar from '../FilterBar/FilterBar';
import { statusDictionary } from '../OrdersTable/OrdersTable.types';
import { ordersUtils } from '../utils';

import './style.scss';

export const DEFAULT_START_DATE = '2023-01-01T00:00:00.000Z';
export const DEFAULT_END_DATE = dayjs()
  .utc()
  .set('hour', 23)
  .set('minute', 59)
  .set('second', 59)
  .toISOString();

const FreeOrdersTable: React.FC = () => {
  const { enqueueSnackbar } = useNotifications();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    page,
    rows,
    sortValue,
    direction,
    statuses,
    publisherPurchaseId,
    playerId,
    orderId
  } = usePaginationFromURL('orders');
  const [currentPage, setCurrentPage] = useState(page);
  const [rowsPerPage, setRowsPerPage] = useState(rows);
  const [isSuccessSearch, setIsSuccessSearch] = useState<boolean>(false);
  const [formattedRangeDates, setFormattedRangeDates] = useState<
    string[] | null[]
  >([null, null]);
  const [sorting, setSorting] = useState<{
    sortValue: SortingOrderValue;
    direction: SortingDirection;
  }>({
    sortValue: sortValue,
    direction: direction
  });
  const versionDetails = localStorageUtil.getAny<User>(
    ELocalStorageKeys.USER_DETAILS
  )?.version;
  const projectsDetails = localStorageUtil.getAny<User>(
    ELocalStorageKeys.USER_DETAILS
  )?.projects;

  const { fetchFeatureFlags } = useUsers(
    false,
    versionDetails,
    undefined,
    false
  );
  const [useReceiptFeature, setUseReceiptFeature] = useState<boolean>(false);

  const { getPermissions } = usePermissions();
  const permissions = getPermissions();
  const allowedPublishers =
    permissionsUtil.getAccessPublisherOrders(permissions);

  const [selectedProjects, setSelectedProjects] = useState<string[]>(
    projectsDetails
      ? getSelectProjectOptions(projectsDetails, allowedPublishers).map(
          (project) => project.value
        )
      : []
  );

  let filters: any = {
    orderId,
    playerId,
    publisherPurchaseId,
    statuses,
    startDate: formattedRangeDates[0] || DEFAULT_START_DATE,
    endDate: formattedRangeDates[1] || DEFAULT_END_DATE,
    selectedProjects: selectedProjects.join(',')
  };
  const { getOrders: orders } = useOrders(
    undefined,
    { currentPage, rowsPerPage },
    sorting,
    {
      isFree: true,
      selectedProjects: selectedProjects.join(','),
      ...filters
    },
    versionDetails
  );

  const getSearchResult = (searchValue: NullableString) =>
    setIsSuccessSearch(!isEmptyString(searchValue));

  const timeout = useRef<any>(null);
  const debounce = (func: any, wait = 250) => {
    timeout && clearTimeout(timeout.current);
    timeout.current = setTimeout(func, wait);
  };

  const REFUNDABLE_STATUSES: EOrderStatus[] = [
    //TODO update list for status filter
    EOrderStatus.payment_dispute_open,
    EOrderStatus.payment_dispute_lost,
    EOrderStatus.payment_dispute_won,
    EOrderStatus.payment_succeed,
    EOrderStatus.charge_succeed,
    EOrderStatus.charge_failed
  ];

  const totalCount = orders.data?.totalCount || 0;
  const [csvData, setCsvData] = useState<any[]>([]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('page', String(currentPage));
    queryParams.set('rows', String(rowsPerPage));
    queryParams.set('sortValue', sorting.sortValue);
    queryParams.set('direction', sorting.direction);

    const navigationOpt: {
      replace: boolean;
      state?: Record<string, boolean>;
    } = { replace: true };
    if (location?.state) {
      navigationOpt.state = location.state;
    }

    navigate(`${location.pathname}?${queryParams.toString()}`, navigationOpt);
  }, [currentPage, rowsPerPage, sorting]);

  useEffect(() => {
    if (!orders.data) return;
    const filteredData = orders.data?.orders.map((record: any) => {
      const modifiedRecord = {
        'Created at': record.createdAt,
        id: record._id,
        'Client Facebook ID': record.clientFbId,
        'Bundle name': record.bundleName,
        'Formatted amount': record.amountFormatted,
        Currency: record.currency,
        'Total amount': record.amountTotal.toNumber(),
        'UTM source': record.utmSource,
        'UTM medium': record.utmMedium,
        'UTM campaign': record.utmCampaign,
        State: record.state,
        Reason: record.reason,
        Provider: record.provider,
        'Publisher payment ID': record.publisherPurchaseId
      };
      return modifiedRecord;
    });
    setCsvData(filteredData);
  }, [orders.data]);

  useEffect(() => {
    if (permissionsUtil.isNewVersion()) {
      if (!fetchFeatureFlags.data) return;
      setUseReceiptFeature(
        fetchFeatureFlags.data.featureFlags.dashboard_display_receipt_number
      );
    } else setUseReceiptFeature(true);
  }, [fetchFeatureFlags.data]);

  if (orders.isError) {
    enqueueSnackbar(
      'Could not load orders list from server',
      ENotificationType.ERROR
    );
  }

  const freeOrderColumns: any = useMemo(
    () => [
      ...(projectsDetails
        ? [
            {
              field: 'project',
              minWidth: 200,
              headerName: 'Project',
              flex: 0.2,
              valueGetter: (params: any) => {
                const currentPublisherId = params.row.publisherId;
                return projectsDetails?.find(
                  (project) => project.publisherId === currentPublisherId
                )?.publisherName;
              }
            }
          ]
        : []),
      {
        field: 'createdAt',
        minWidth: 150,
        flex: 0.2,
        headerName: 'Date and Time UTC',
        renderCell: (params: any) => {
          if (orders.isLoading) return '';
          return (
            <span>
              {dayjs(params.row.createdAt).utc().format(DATE_TIME_FORMAT)}
            </span>
          );
        },
        valueGetter: (params: any) => {
          return params.row.createdAt;
        },
        filterable: false
      },
      {
        field: 'offerSnapshot.type',
        headerName: 'Type',
        minWidth: 100,
        flex: 0.2,
        ColumnUnsortedIcon: null,
        disableReorder: true,
        filterable: false,
        valueGetter: (params: any) => {
          return params.row.offerSnapshot?.type ?? 'N/A';
        }
      },
      {
        field: 'offerSnapshot.subType',
        headerName: 'Sub Type',
        minWidth: 100,
        flex: 0.2,
        ColumnUnsortedIcon: null,
        disableReorder: true,
        filterable: false,
        valueGetter: (params: any) => {
          return params.row.offerSnapshot?.subType ?? 'N/A';
        }
      },
      {
        field: 'offerSnapshot.sequenceIndex',
        headerName: 'Product Sequence',
        minWidth: 100,
        flex: 0.2,
        ColumnUnsortedIcon: null,
        disableReorder: true,
        filterable: false,
        valueGetter: (params: any) => {
          return params.row.offerSnapshot?.sequenceIndex ?? 'N/A';
        }
      },
      {
        field: 'amountTotal',
        headerName: 'Price',
        minWidth: 100,
        flex: 0.2,
        ColumnUnsortedIcon: null,
        disableReorder: true,
        filterable: false,
        valueGetter: () => {
          return 'Free';
        }
      },
      {
        field: 'bundleName',
        minWidth: 150,
        headerName: 'Name',
        flex: 0.2,
        filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === 'contains'
        ),
        filterable: false
      },
      {
        field: 'amountFormatted',
        headerName: 'Price',
        minWidth: 100,
        flex: 0.2,
        renderCell: (): string => 'FREE',
        filterable: false
      },
      {
        field: 'orderId',
        headerName: 'Order ID',
        minWidth: 200,
        flex: 0.2,
        renderCell: (params: any) => {
          return params.row.orderId || 'N/A';
        },
        filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === 'contains'
        ),
        filterable: false
      },
      {
        field: 'playerId',
        headerName: 'Player ID',
        minWidth: 100,
        flex: 0.2,
        filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === 'contains'
        ),
        filterable: false
      },
      {
        field: 'publisherPurchaseId',
        headerName: 'Transaction ID',
        minWidth: 100,
        flex: 0.2,
        ColumnUnsortedIcon: null,
        disableReorder: true,
        renderCell: (params: any) => {
          return params.row.publisherPurchaseId || 'N/A';
        },
        filterOperators: getGridStringOperators().filter(
          (operator) => operator.value === 'contains'
        ),
        filterable: false
      },
      {
        field: 'state',
        headerName: 'Status',
        flex: 0.2,
        minWidth: 270,
        renderCell: ({ value }: any) => {
          if (orders.isLoading) return value;
          return (
            <StatusLabel
              text={
                statusDictionary[value as EOrderStatus]?.text ===
                'Failed (charged)'
                  ? 'Failed'
                  : statusDictionary[value as EOrderStatus]?.text
              }
              status={statusDictionary[value as EOrderStatus]?.status}
              prefixIcon={statusDictionary[value as EOrderStatus]?.prefixIcon}
            />
          );
        },
        valueGetter: (params: any) => {
          return params.row.state;
        },
        filterable: false
      },
      {
        field: 'Actions',
        flex: 0,
        type: 'actions',
        width: 30,
        disableReorder: true,
        filterable: false,
        hideable: false,
        getActions: (params: any) => [
          <GridActionsCellItem
            icon={<VisibilityOutlinedIcon />}
            label="View"
            onClick={() => {
              const basePath = location.pathname.split('/orders')[0];
              navigate(`${basePath}/orders/free/${params.id}`, {
                state: {
                  state: params.row.state,
                  isOver30Days:
                    dayjs().diff(dayjs(params.row.createdAt), 'days') >= 30,
                  publisherId: params.row.publisherId
                }
              });
            }}
            showInMenu
          />
        ]
      }
    ],
    [orders.isLoading, projectsDetails]
  );

  const handleSortModelChange = (model: GridSortModel) => {
    if (model.length > 0) {
      const field = model[0].field;
      const mappedSortValue = ordersUtils.fieldToSortingOrderValue[field];
      if (mappedSortValue) {
        setSorting({
          sortValue: mappedSortValue,
          direction:
            model[0].sort === SortingDirection.ASC
              ? SortingDirection.ASC
              : SortingDirection.DESC
        });
      }
    }
  };

  const allowedPublishersList = useMemo(
    () =>
      permissionsUtil
        .getAccessPublisherOrders(permissions)
        .filter(
          (publisher) =>
            projectsDetails?.find((item) => item.publisherId === publisher)
              ?.projectType !== EPublisherSolutionModel.CHECKOUT
        ),
    [permissions, projectsDetails]
  );

  const getAllowedFreeOrdersList = () => {
    if (!orders.data?.orders) return [];
    if (versionDetails !== NEW_VERSION) return orders.data?.orders;
    return (
      orders.data?.orders.filter((order) =>
        allowedPublishersList.includes(order.publisherId)
      ) ?? []
    );
  };

  return (
    <>
      <Stack>
        {projectsDetails && (
          <Stack direction="row" gap={2}>
            <ProjectsSelect
              projectsDetails={projectsDetails}
              selectedProjects={selectedProjects}
              setSelectedProjects={setSelectedProjects}
              isApplyBtnEnabled={true}
              allowedPublishers={allowedPublishersList}
            />
          </Stack>
        )}
        <FilterBar
          projectsDetails={projectsDetails}
          useReceiptFeature={useReceiptFeature}
          setFormattedRangeDates={setFormattedRangeDates}
          formattedRangeDates={formattedRangeDates}
          getSearchResult={getSearchResult}
          isFree={true}
        />
        <DataTable
          columns={freeOrderColumns}
          rows={getAllowedFreeOrdersList()}
          loading={orders.isLoading}
          onPageChange={(newPage) => setCurrentPage(newPage)}
          onRowsPerPageChange={(newRowsPerPage) => {
            setRowsPerPage(newRowsPerPage);
            setCurrentPage(0);
          }}
          sortingMode={GridFeatureMode.SERVER}
          onSortModelChange={handleSortModelChange}
          defaultHiddenFields={['amountFormatted']}
          currentPage={currentPage}
          totalCount={totalCount}
          localStorageColumnsKey={ELocalStorageKeys.ORDERS_COLUMN_VISIBILITY}
          hideFooter={false}
          error={orders.isError}
          initialSorting={{
            sortModel: [
              {
                field: ordersUtils.sortValueToFieldMap[sorting.sortValue],
                sort:
                  sorting.direction === SortingDirection.ASC
                    ? SortingDirection.ASC
                    : SortingDirection.DESC
              }
            ]
          }}
          onNoData={
            isSuccessSearch && orders.data?.orders.length === 0 ? (
              <FirstActionModal
                headline="No orders found"
                text="Your order was not found. Please check your spelling or try different keywords."
              />
            ) : (
              <FirstActionModal
                headline="No free orders"
                text="Currently there is no orders in your store"
              />
            )
          }
          pagination={true}
        />
      </Stack>
    </>
  );
};

export default FreeOrdersTable;
