import { Fade, Grow, Stack, Typography } from '@mui/material';
import { env } from 'process';

const CheckoutThemePreview = ({
  logo,
  primaryColor,
  textColor,
  buttonColor,
  buttonTextColor,
  bgImage,
  onClose
}: {
  logo: string;
  primaryColor: string;
  textColor: string;
  buttonColor: string;
  buttonTextColor: string;
  bgImage: string;
  onClose: () => void;
}) => {
  return (
    <Stack
      width="100vw"
      height="100vh"
      position="absolute"
      top={0}
      left={0}
      zIndex={9999}
      justifyContent="center"
      alignItems="center"
      sx={{
        fontFamily: "'Montserrat', sans-serif",
        backgroundColor: 'rgba(0, 0, 0, 0.75)'
      }}
      onClick={() => onClose()}
    >
      <Grow in>
        <Stack
          width="740px"
          height="740px"
          sx={{ backgroundColor: 'whitesmoke' }}
          borderRadius="15px"
          direction="row"
        >
          <Stack
            width="296px"
            sx={{
              background: primaryColor,
              backgroundImage: `url(${bgImage})`,
              borderTopLeftRadius: '15px',
              borderBottomLeftRadius: '15px',
              backgroundSize: 'cover',
              backgroundPosition: 'center top'
            }}
            direction="column"
            gap={2}
            px={'32px'}
            py={'16px'}
          >
            <Stack height="68px" mt={2} alignItems="flex-start">
              <img
                src={logo}
                alt="logo"
                style={{ height: '68px', objectFit: 'contain' }}
              />
            </Stack>
            <Stack direction="column">
              <Typography
                variant="h6"
                color={textColor}
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                  fontFamily: "'Montserrat', sans-serif"
                }}
              >
                Crazy Pack
              </Typography>
              <Typography
                variant="h6"
                color={textColor}
                sx={{
                  fontSize: '24px',
                  fontWeight: 600,
                  fontFamily: "'Montserrat', sans-serif"
                }}
              >
                $99.99
              </Typography>
            </Stack>
            <Stack direction="column" gap={2}>
              <hr
                style={{
                  marginTop: '-8px',
                  width: '232px',
                  border: '1px solid white'
                }}
              />
              <Stack direction="row" gap={1.5}>
                <img
                  src="https://media-dev.appcharge.com/media/product-3.png"
                  alt=""
                  style={{
                    width: '48px',
                    height: '48px'
                  }}
                />
                <Stack direction="column" justifyContent="center" gap="4px">
                  <span
                    style={{
                      fontSize: '12px',
                      fontWeight: 400,
                      fontFamily: "'Montserrat', sans-serif",
                      color: textColor
                    }}
                  >
                    Coins
                  </span>
                  <span
                    style={{
                      fontSize: '16px',
                      fontWeight: 600,
                      fontFamily: "'Montserrat', sans-serif",
                      color: textColor
                    }}
                  >
                    6580
                  </span>
                </Stack>
              </Stack>
              <Stack direction="row" gap={1.5}>
                <img
                  src="https://media-dev.appcharge.com/media/bills.png"
                  alt=""
                  style={{
                    width: '48px',
                    height: '48px'
                  }}
                />
                <Stack direction="column" justifyContent="center" gap="4px">
                  <span
                    style={{
                      fontSize: '12px',
                      fontWeight: 400,
                      fontFamily: "'Montserrat', sans-serif",
                      color: textColor
                    }}
                  >
                    Tokens
                  </span>
                  <span
                    style={{
                      fontSize: '16px',
                      fontWeight: 600,
                      fontFamily: "'Montserrat', sans-serif",
                      color: textColor
                    }}
                  >
                    150
                  </span>
                </Stack>
              </Stack>
              <Stack direction="row" gap={1.5}>
                <img
                  src="https://media-dev.appcharge.com/media/booster.png"
                  alt=""
                  style={{
                    width: '48px',
                    height: '48px'
                  }}
                />
                <Stack direction="column" justifyContent="center" gap="4px">
                  <span
                    style={{
                      fontSize: '12px',
                      fontWeight: 400,
                      fontFamily: "'Montserrat', sans-serif",
                      color: textColor
                    }}
                  >
                    Boosters
                  </span>
                  <span
                    style={{
                      fontSize: '16px',
                      fontWeight: 600,
                      fontFamily: "'Montserrat', sans-serif",
                      color: textColor
                    }}
                  >
                    3
                  </span>
                </Stack>
              </Stack>
              <hr
                style={{
                  marginBottom: '16px',
                  width: '232px',
                  border: '1px solid white'
                }}
              />
              <Stack direction="column" gap={1.25}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{
                    fontSize: '12px',
                    fontWeight: 400,
                    fontFamily: "'Montserrat', sans-serif",
                    color: textColor
                  }}
                >
                  <span>Subtotal</span>
                  <span>$ 13.25</span>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{
                    fontSize: '12px',
                    fontWeight: 400,
                    fontFamily: "'Montserrat', sans-serif",
                    color: textColor
                  }}
                >
                  <span>Tax (17%)</span>
                  <span>$ 2.25</span>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    fontFamily: "'Montserrat', sans-serif",
                    color: textColor
                  }}
                >
                  <span>Total</span>
                  <span>$ 15.50</span>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            width="100%"
            sx={{
              background: '#f4f4f8',
              borderTopRightRadius: '15px',
              borderBottomRightRadius: '15px'
            }}
            pt={2}
            alignItems="center"
            position={'relative'}
          >
            <div
              onClick={() => onClose()}
              style={{
                cursor: 'pointer',
                color: '#000000',
                width: '25px',
                height: '25px',
                position: 'absolute',
                right: '16px'
              }}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="25px"
                width="25px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z"></path>
              </svg>
            </div>
            <img
              src={'./assets/images/checkout_theme_payments.png'}
              alt="checkout-theme-preview"
            />
            <Stack width="100%" px={6}>
              <Stack
                mt={1}
                mb={1}
                width="90px"
                height="80px"
                bgcolor="#ffffff"
                borderRadius="8px"
                justifyContent="center"
                alignItems="center"
                borderColor={'red'}
                sx={{
                  border: `1px solid ${buttonColor}`
                }}
              >
                <img
                  src={'./assets/images/checkout_theme_creditcard_box.png'}
                  style={{
                    width: '83px',
                    height: '75px'
                  }}
                  alt="checkout-theme-preview"
                />
              </Stack>
            </Stack>
            <img
              src={'./assets/images/checkout_theme_creditcard.png'}
              alt="checkout-theme-preview"
            />
            <button
              style={{
                width: '349px',
                background: buttonColor,
                paddingTop: '12px',
                paddingBottom: '12px',
                paddingLeft: '16px',
                paddingRight: '16px',
                borderRadius: '4px',
                border: 0,
                fontSize: '16px',
                fontFamily: "'Montserrat', sans-serif",
                fontWeight: 500,
                color: buttonTextColor,
                marginBottom: '8px',
                marginTop: '8px'
              }}
            >
              Pay $ 15.50
            </button>
            <img
              src={'./assets/images/checkout_theme_footer.png'}
              alt="checkout-theme-preview"
            />
          </Stack>
        </Stack>
      </Grow>
    </Stack>
  );
};

export default CheckoutThemePreview;
