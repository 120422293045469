import { createSlice } from '@reduxjs/toolkit';

import { AuthSliceState } from './store.types';

const initialAuthSliceState: AuthSliceState = {
  authJwt: null,
  userId: '',
  currentPublisherId: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthSliceState,
  reducers: {
    login(state: AuthSliceState, action) {
      if (action.payload?.authJwt) state.authJwt = action.payload.authJwt;
      // if (action.payload?.currentPublisherId) state.currentPublisherId = action.payload.currentPublisherId;
      state.userId = action.payload.userId;
    },
    setCurrentPublisherId(state: AuthSliceState, action) {
      state.currentPublisherId = action.payload.currentPublisherId;
    },
    clearState() {
      return initialAuthSliceState;
    }
  }
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
