import React, { ChangeEvent, useState } from 'react';

import { Grid, Stack } from '@mui/material';
import { useProduct } from 'api/useProduct';
import {
  PopUpProductSequence,
  ProductsSequenceProduct
} from 'common/contracts';
import { EAssetType, EPopupSubType, EProductType } from 'constants/enums';
import { v4 as uuidv4 } from 'uuid';
import { ProductProps } from 'views/Popups/types';

import AcCard from 'components/AcCard/AcCard';
import { Product } from 'components/ProductsForm/ProductsForm.types';

import ActionButton from '../../../../components/ActionButton/ActionButton';
import { transformQuantityValue } from '../../../../utils/formattingUtil';
import DailyBonusProducts from '../../Products/DailyBonusProducts';
import ProductSelector from '../../Products/ProductSelector';
import ProductTable from '../../Products/ProductTable';

const ProductsTab: React.FC<any> = (props: any) => {
  const {
    formikProps,
    values,
    products,
    setProducts,
    formatProductQuantity,
    currentPublisherId,
    enrichedProductDetails,
    setHoveredProduct,
    productSequence,
    setProductSequence
  } = props;
  const {
    handleChange,
    handleBlur,
    touched,
    setTouched,
    errors,
    setFieldValue
  } = formikProps;

  const [productImage, setProductImage] = useState('');
  const [productQuantity, setProductQuantity] = useState<number | string>('');
  const [isProductSequenceEditMode, setIsProductSequenceEditMode] =
    useState(false);
  const [currentProduct, setCurrentProduct] =
    useState<ProductsSequenceProduct | null>(null);
  const [currentSequence, setCurrentProductSequence] =
    useState<PopUpProductSequence | null>(null);

  const { data: productsData, isLoading: isLoadingProducts } =
    useProduct(currentPublisherId).getProducts;

  const handleQuantityChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputValue = e.target.value;
    const newOffersQuantity =
      inputValue.length !== 0 ? transformQuantityValue(inputValue) : inputValue;
    setTouched({ ...touched, quantity: true });
    setProductQuantity(newOffersQuantity);
    setCurrentProduct({
      productId: currentProduct?.productId || '',
      quantity: newOffersQuantity
    });
  };

  const updateProductSequence = async () => {
    if (!currentSequence) return;

    const currentProduct = currentSequence.products[0].product;
    const foundProduct = productsData?.result.find(
      (product: Product) => product.productId === productImage
    );

    const formattedProductQuantity = await formatProductQuantity.mutateAsync({
      quantity: currentSequence.products[0].quantity?.toString(),
      productType: foundProduct.type
    });

    if (!foundProduct) {
      console.error('Product not found');
      return;
    }

    const updatedProduct = {
      ...currentProduct,
      productId: productImage,
      images: currentProduct?.images?.map((i) =>
        i.type === EAssetType.PRODUCT
          ? { ...i, url: foundProduct.images[0].url }
          : i
      ),
      publisherProductId: foundProduct.publisherProductId,
      name: foundProduct.name,
      type: 'Quantity',
      textFontColorHex: foundProduct.textFontColorHex,
      prefix: foundProduct.prefix,
      suffix: foundProduct.suffix
    };

    const updatedSequence = {
      ...currentSequence,
      products: [{ product: updatedProduct, quantity: productQuantity }]
    };

    const updatedProductSequences = productSequence.map((seq: any) =>
      seq.id === currentSequence.id ? updatedSequence : seq
    );

    setProductSequence(updatedProductSequences);
    setFieldValue('productsSequence', [
      ...values.productsSequence,
      updatedProductSequences
    ]);
    resetEditState();
  };

  const createNewProductSequence = async () => {
    const foundProduct = productsData?.result.find(
      (product: Product) => product.productId === productImage
    );

    if (!foundProduct) {
      console.error('Product not found');
      return;
    }

    const formattedProductQuantity = await formatProductQuantity.mutateAsync({
      quantity: productQuantity.toString(),
      productType: foundProduct.type
    });

    handleChange({
      target: {
        name: 'quantity',
        value: String(productQuantity)
      }
    });

    const newProduct = {
      productId: foundProduct.productId,
      quantity: productQuantity,
      quantityDisplay: formattedProductQuantity.data,
      product: {
        ...foundProduct,
        images: foundProduct.images,
        publisherProductId: foundProduct.publisherProductId,
        name: foundProduct.name,
        type: 'Quantity',
        textFontColorHex: foundProduct.textFontColorHex,
        prefix: foundProduct.prefix,
        suffix: foundProduct.suffix
      }
    };

    if (
      productSequence.length === 1 &&
      productSequence[0].products.length === 0
    ) {
      setProductSequence([
        {
          ...productSequence[0],
          products: [newProduct]
        }
      ]);
    } else {
      const maxIndex = productSequence.reduce(
        (max: number, seq: any) => Math.max(max, seq.index),
        0
      );

      const newSequence = {
        id: uuidv4(),
        index: maxIndex + 1,
        products: [newProduct],
        priceInUsdCents: 0
      };

      setProductSequence([...productSequence, newSequence]);
      setFieldValue('productsSequence', [
        ...values.productsSequence,
        newSequence
      ]);
    }

    resetEditState();
  };

  const duplicateProductSequence = (sequenceId: string) => {
    const sequenceToDuplicate = productSequence.find(
      (seq: any) => seq.id === sequenceId
    );
    if (!sequenceToDuplicate) {
      console.error('Sequence not found for duplication');
      return;
    }

    const newSequence = JSON.parse(JSON.stringify(sequenceToDuplicate));

    const maxIndex = Math.max(
      ...productSequence.map((seq: any) => seq.index),
      0
    );
    newSequence.index = maxIndex + 1;
    newSequence.id = uuidv4();

    setProductSequence([...productSequence, newSequence]);
    setFieldValue('productsSequence', [
      ...values.productsSequence,
      newSequence
    ]);
  };

  const removeProductSequence = (sequenceId: string) => {
    const sequenceIndexToRemove = productSequence.findIndex(
      (seq: any) => seq.id === sequenceId
    );

    if (sequenceIndexToRemove === -1) {
      console.error('Sequence not found');
      return;
    }

    const updatedProductSequences = productSequence.filter(
      (seq: any) => seq.id !== sequenceId
    );

    // Update the indices of sequences that come after the removed sequence -
    for (
      let i = sequenceIndexToRemove;
      i < updatedProductSequences.length;
      i++
    ) {
      updatedProductSequences[i].index = updatedProductSequences[i].index - 1;
    }
    const updatedProductSequencesFormat =
      updatedProductSequences.length === 0
        ? [
            {
              index: 1,
              priceInUsdCents: 0,
              products: []
            }
          ]
        : updatedProductSequences;

    setProductSequence(updatedProductSequencesFormat);
    setFieldValue('productsSequence', updatedProductSequencesFormat);

    // Reset the edit state if the deleted sequence is being edited
    if (currentSequence && currentSequence.id === sequenceId) {
      resetEditState();
    }
  };

  const editProduct = (sequenceId: string) => {
    const sequence = productSequence.find((seq: any) => seq.id === sequenceId);
    setCurrentProductSequence(sequence || null);
    setIsProductSequenceEditMode(true);

    if (sequence) {
      setProductImage(sequence.products[0].product?.productId || '');
      setProductQuantity(sequence.products[0].quantity || '');
    }
  };

  const resetEditState = () => {
    setCurrentProductSequence(null);
    setProductImage('');
    setProductQuantity('');
    setIsProductSequenceEditMode(false);
  };

  const handleSectionsOrderChange = (targetIndex: number, oldIndex: number) => {
    if (
      targetIndex < 0 ||
      oldIndex < 0 ||
      targetIndex >= productSequence.length ||
      oldIndex >= productSequence.length
    ) {
      return;
    }

    const newProductSequence = [...productSequence];
    const [removedItem] = newProductSequence.splice(oldIndex, 1);
    newProductSequence.splice(targetIndex, 0, removedItem);

    newProductSequence.forEach((sequence, index) => {
      sequence.index = index + 1;
    });

    setProductSequence(newProductSequence);
    setFieldValue('productsSequence', newProductSequence);
  };

  const removePostPurchaseProduct = (productId: string) => {
    const newProducts = products.filter(
      (p: ProductProps) => p._id !== productId
    );
    setProducts(newProducts);

    setFieldValue(
      'productsSequence[0].products',
      newProducts.map((p: ProductProps) => ({
        productId: p.productId,
        quantity: p.amount
      }))
    );
  };

  const editPostPurchaseProducts = (productId: string) => {
    return products.forEach((p: ProductProps) => {
      if (p._id === productId) {
        const newProducts = products.filter(
          (p: ProductProps) => p._id !== productId
        );
        setProducts(newProducts);
        setCurrentProduct({
          product: p.image,
          productId: p._id,
          quantity: p.amount
        });
      }
    });
  };

  const addPostPurchaseProduct = async () => {
    const foundProduct = productsData!.result.find(
      (product: { productId: string | undefined }) =>
        product.productId === currentProduct?.productId
    );

    const formattedProductQuantity = await formatProductQuantity.mutateAsync({
      quantity: currentProduct!.quantity.toString(),
      productType: foundProduct.type
    });

    const newProduct = {
      _id: currentProduct!.productId!,
      productId: currentProduct!.productId!,
      image: foundProduct!.images[0].url,
      imagePrefix: foundProduct.images?.find((i: { type: EAssetType }) => {
        return i.type === EAssetType.PRODUCT_PREFIX;
      })?.url,
      name: foundProduct!.name,
      amount: currentProduct!.quantity,
      type: foundProduct!.type,
      textFontColorHex: foundProduct!.textFontColorHex,
      prefix: foundProduct!.prefix,
      suffix: foundProduct!.suffix,
      quantityDisplay: formattedProductQuantity.data
    };

    setProducts([...products, newProduct]);

    setFieldValue('productsSequence[0].products', [
      ...values.productsSequence[0].products,
      {
        productId: newProduct.productId,
        quantity: newProduct.amount
      }
    ]);

    setCurrentProduct({
      productId: '',
      quantity: 0
    });
  };

  const handleRowMouseEnter = (rowId: string) => {
    setHoveredProduct(
      enrichedProductDetails.filter(
        (productDetail: any) => productDetail._id === rowId
      )
    );
  };

  const flattenedSequence = productSequence.flatMap((seq: any) => {
    if (seq.products.length === 0) {
      return [];
    }
    return [
      {
        ...seq.products[0].product,
        image: seq.products[0].product?.images?.[0]?.url,
        imagePrefix: seq.products[0]?.product?.images?.find(
          (i: any) => i.type === EAssetType.PRODUCT_PREFIX
        )?.url,
        amount: seq.products[0].quantity,
        sequenceIndex: seq.index,
        _id: seq.id
      }
    ];
  });

  return (
    <Stack gap={2}>
      {values.subType === EPopupSubType.DAILY_BONUS ? (
        <DailyBonusProducts
          values={values}
          handleChange={handleChange}
          handleBlur={handleBlur}
          productImage={productImage}
          setProductImage={setProductImage}
          handleQuantityChange={handleQuantityChange}
          productQuantity={productQuantity}
          isProductSequenceEditMode={isProductSequenceEditMode}
          updateProductSequence={updateProductSequence}
          createNewProductSequence={createNewProductSequence}
          resetEditState={resetEditState}
          productSequence={productSequence}
          flattenedSequence={flattenedSequence}
          handleSectionsOrderChange={handleSectionsOrderChange}
          handleRowMouseEnter={handleRowMouseEnter}
          isLoadingProducts={isLoadingProducts}
          productsData={productsData}
          editProduct={editProduct}
          duplicateProductSequence={duplicateProductSequence}
          removeProductSequence={removeProductSequence}
        />
      ) : (
        <AcCard stackContainer={false} title="Products">
          <Grid container rowSpacing={1.5} columnSpacing={1.5}>
            <ProductSelector
              currentProduct={currentProduct}
              setCurrentProduct={setCurrentProduct}
              handleBlur={handleBlur}
              handleQuantityChange={handleQuantityChange}
              products={products}
              productsData={productsData}
              isLoadingProducts={isLoadingProducts}
              touched={touched}
              errors={errors}
            />
            <Grid item xs={12}>
              <ActionButton
                text={'Add'}
                variant="outlined"
                disabled={
                  !currentProduct?.productId ||
                  !currentProduct?.quantity ||
                  products.length >= 1
                }
                onClick={addPostPurchaseProduct}
              />
              {products.length >= 1 && (
                <div
                  style={{
                    marginTop: '12px'
                  }}
                >
                  *You can only add one product
                </div>
              )}
            </Grid>
            <ProductTable
              products={products}
              removeProduct={removePostPurchaseProduct}
              editProducts={editPostPurchaseProducts}
            />
          </Grid>
        </AcCard>
      )}
    </Stack>
  );
};

export default ProductsTab;
