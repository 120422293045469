import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { User } from '../common/contracts';
import { NEW_VERSION } from '../constants/constants';
import { ELocalStorageKeys, EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';
import { localStorageUtil } from '../utils/localStorageUtil';

import { EApiRoutes, fetchConfig, getApiRouteOrMock } from './api.utils';

export default function useTags(
  currentPublisherId: string | null,
  hasFeatureFlagTags?: boolean
) {
  const axios = useAxios();
  const [isOfferTagsEnabled, setOfferTagsEnabled] = useState(false);

  const versionDetails = localStorageUtil.getAny<User>(
    ELocalStorageKeys.USER_DETAILS
  )?.version;

  useEffect(() => {
    if (hasFeatureFlagTags) {
      setOfferTagsEnabled(true);
    }
  }, [hasFeatureFlagTags]);

  const getTags = useQuery({
    ...fetchConfig.list,
    queryKey: [EQueryKeys.PUBLISHER_TAGS],
    queryFn: async () => {
      return await axios.get(getApiRouteOrMock(EApiRoutes.PUBLISHER_TAGS));
    },
    enabled:
      isOfferTagsEnabled &&
      (versionDetails === NEW_VERSION ? !!currentPublisherId : true)
  });

  return {
    getTags
  };
}
