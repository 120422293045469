import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { AuthSliceState } from '../../store/store.types';
import AcLink from '../AcLink/AcLink';

import { SidebarItemProps } from './SidebarItem.types';

import './style.scss';

const SidebarItem = ({ data }: SidebarItemProps) => {
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );
  const location = useLocation();
  const sliceCount = currentPublisherId ? 4 : 3;
  const pathSegments = location.pathname.split('/')?.splice(0, sliceCount);
  const routeSegments = `/${data.route}`?.split('/')?.splice(0, sliceCount);
  const isActive = routeSegments?.every(
    (segment, index) =>
      pathSegments.includes(segment) && pathSegments.indexOf(segment) >= index
  );

  return (
    <>
      {data.disabled ? (
        <span>
          <li className={'sidebarItem disabled'}>
            {data.icon}
            <h3>{data.title}</h3>
          </li>
        </span>
      ) : data.route ? (
        <AcLink
          to={`./${data.route}${
            data.defaultQuery ? `?${data.defaultQuery}` : ''
          }`}
        >
          <li className={`sidebarItem ${isActive && 'active'}`}>
            <div>{data.icon}</div>
            <div>{data.title}</div>
          </li>
        </AcLink>
      ) : (
        <li
          className="sidebarItem"
          onClick={() => data.onClick && data.onClick()}
        >
          {data.icon}
          <h3>{data.title}</h3>
        </li>
      )}
    </>
  );
};

export default SidebarItem;
