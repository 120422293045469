import React from 'react';

import { Box } from '@mui/system';

const AcContentWrapper = ({
  children,
  className
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <Box className={className + ' content-wrapper'} px={'3rem'}>
      {children}
    </Box>
  );
};

export default AcContentWrapper;
