import { useEffect, useState } from 'react';

import {
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  TextField,
  TextFieldProps
} from '@mui/material';

import './style.scss';

const AcSearch = (
  props: TextFieldProps & {
    categories?: string[];
    callback?: (value: string, category?: string) => void;
  }
) => {
  const [category, setCategory] = useState('All');
  const [value, setValue] = useState('');
  useEffect(() => {
    props.callback!(value, category);
  }, [value, category, props.callback]);
  return (
    <div className="search-bar">
      <Stack direction="row">
        {props.categories && props.categories.length > 0 && (
          <Select
            value={category}
            onChange={(event) => setCategory(event.target.value)}
          >
            {props.categories.map((category, key) => {
              return (
                <MenuItem key={key} value={category}>
                  {category}
                </MenuItem>
              );
            })}
          </Select>
        )}
        <TextField
          variant={props.variant ?? 'outlined'}
          sx={{ width: '100%' }}
          {...props}
          value={value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setValue(event.target.value);
          }}
        />
        {props.helperText && <FormHelperText></FormHelperText>}
      </Stack>
    </div>
  );
};

export default AcSearch;
