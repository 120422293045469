import { ChangeEvent, useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Stack
} from '@mui/material';
import { GridSearchIcon } from '@mui/x-data-grid';
import useOffers from 'api/useOffers';
import { useProduct } from 'api/useProduct';
import useSegments from 'api/useSegments';
import useTags from 'api/useTags';
import useUsers from 'api/useUsers';
import {
  EBadgeType,
  OfferUI,
  ProductsSequenceProduct,
  User
} from 'common/contracts';
import { MIN_USD_PRICE } from 'constants/constants';
import {
  EAssetType,
  EFeatureFlag,
  ELocalStorageKeys,
  ESaleDiscountDisplayType,
  OfferType
} from 'constants/enums';
import { AuthSliceState } from 'store/store.types';
import { localStorageUtil } from 'utils/localStorageUtil';

import AcAutoComplete from 'components/AcAutoComplete/AcAutoComplete';
import AcCard from 'components/AcCard/AcCard';
import AcInput from 'components/AcInput/AcInput';
import AcSelect from 'components/AcSelect/AcSelect';
import { EAcSelectItemRenderType } from 'components/AcSelect/AcSelect.types';
import DataTable from 'components/DataTable/DataTable';
import DisplayTypeSelect from 'components/DisplayTypeSelect/DisplayTypeSelect';
import { Product } from 'components/ProductsForm/ProductsForm.types';

import ActionButton from '../../../components/ActionButton/ActionButton';
import { transformQuantityValue } from '../../../utils/formattingUtil';

const SettingsTab = (props: any) => {
  const {
    values,
    setCurrentOfferUI,
    chosenSegment,
    setChosenSegment,
    hasFeatureFlagTags,
    formikProps,
    getOffersUI,
    specialOfferId,
    products,
    setProducts,
    edit,
    relevantBadgeType,
    badgeList,
    setDirtyProducts
  } = props;

  const {
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    setTouched,
    validateField,
    validateForm
  } = formikProps;
  const currentPublisherId = useSelector(
    ({ auth }: { auth: AuthSliceState }) => auth.currentPublisherId
  );

  const versionDetails = localStorageUtil.getAny<User>(
    ELocalStorageKeys.USER_DETAILS
  )?.version;

  const { fetchFeatureFlags } = useUsers(
    false,
    versionDetails,
    currentPublisherId,
    true
  );

  const hasScheduleOfferFeatureFlag =
    fetchFeatureFlags.data?.featureFlags?.[
      EFeatureFlag.DASHBOARD_SCHEDULED_OFFERS
    ];

  const [skuExists, setSkuExists] = useState<boolean>(false);
  const [segmentError, setSegmentError] = useState('');
  const [tagsError, setTagsError] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [currentProduct, setCurrentProduct] =
    useState<ProductsSequenceProduct | null>(null);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const { getOffers, formatProductQuantity } = useOffers(
    specialOfferId,
    OfferType.SPECIAL_OFFER
  );
  const { data: productsData, isLoading: isLoadingProducts } =
    useProduct(currentPublisherId).getProducts;
  const { getSegments } = useSegments(currentPublisherId);
  const { getTags } = useTags(currentPublisherId, hasFeatureFlagTags);

  useEffect(() => {
    setSkuExists(
      getOffers.data?.offers.find((o: any) => {
        return o.publisherOfferId === values.publisherOfferId;
      }) && !edit
    );
  }, [values.publisherOfferId]);

  useEffect(() => {
    setFieldValue('segments', chosenSegment);
  }, [chosenSegment]);

  const handleQuantityChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newQuantity =
      e.target.value.length !== 0
        ? transformQuantityValue(e.target.value)
        : e.target.value;
    setTouched({ ...touched, quantity: true });
    handleChange({
      target: {
        name: 'quantity',
        value: String(newQuantity)
      }
    });
    setCurrentProduct({
      productId: currentProduct?.productId || '',
      quantity: newQuantity
    });
  };

  const removeProduct = (productId: string) => {
    setDirtyProducts(true);
    const newProducts = products.filter(
      (p: ProductsSequenceProduct) => p.productId !== productId
    );
    setProducts(newProducts);
  };

  const editProduct = (productId: string) => {
    return products.forEach((p: any) => {
      if (p._id === productId) {
        removeProduct(productId);
        setCurrentProduct({
          product: p.image,
          productId: p._id,
          quantity: p.amount
        });
      }
    });
  };

  const addProduct = async () => {
    setDirtyProducts(true);
    const foundProduct = productsData!.result.find((pd: Product) => {
      return pd.productId === currentProduct?.productId;
    });
    const formattedProductQuantity = await formatProductQuantity.mutateAsync({
      quantity: currentProduct!.quantity.toString(),
      productType: foundProduct.type
    });
    setProducts([
      ...products,
      {
        priority: 'main',
        _id: currentProduct!.productId!,
        productId: currentProduct!.productId!,
        image: foundProduct!.images[0].url,
        imagePrefix: foundProduct.images?.find(
          (i: any) => i.type === EAssetType.PRODUCT_PREFIX
        )?.url,
        name: foundProduct!.name,
        amount: currentProduct!.quantity,
        type: foundProduct!.type,
        textFontColorHex: foundProduct!.textFontColorHex,
        prefix: foundProduct!.prefix,
        suffix: foundProduct!.suffix,
        quantityDisplay: formattedProductQuantity.data
      }
    ]);
    setCurrentProduct({
      productId: '',
      quantity: 0
    });
  };

  // removes tag
  const removeTag = (tag: string) => {
    const filteredTags = values.tags.filter((s: string) => s !== tag);
    setFieldValue('tags', filteredTags);
  };

  return (
    <Stack>
      <AcCard stackContainer={false} title="General">
        <Grid container rowSpacing={2} columnSpacing={1.5}>
          <Grid item xs={6}>
            <AcInput
              header="Name"
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              required={true}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name ? errors.name?.toString() : ''}
            />
          </Grid>
          <Grid xs={6} item>
            <AcInput
              header="Display Name"
              name="displayName"
              variant="outlined"
              value={values.displayName}
              onChange={(data) => {
                handleChange(data);
              }}
              onBlur={handleBlur}
              error={touched.displayName && Boolean(errors.displayName)}
              tooltip={'Add your display name for this promotion'}
            />
          </Grid>
          <Grid xs={12} item>
            <AcInput
              header="Description"
              name="description"
              variant="outlined"
              value={values.description}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.description && Boolean(errors.description)}
              tooltip={'Add your description for this offer'}
              helperText={
                touched.description ? errors.description?.toString() : ''
              }
            />
          </Grid>
          <Grid item xs={6}>
            <AcInput
              header="SKU"
              name="publisherOfferId"
              value={values.publisherOfferId}
              onChange={handleChange}
              onBlur={handleBlur}
              required={true}
              error={
                (touched.publisherOfferId &&
                  Boolean(errors.publisherOfferId)) ||
                skuExists
              }
              helperText={
                (touched.publisherOfferId
                  ? errors.publisherOfferId?.toString()
                  : '') || (skuExists ? 'SKU already exists' : '')
              }
            />
          </Grid>
          {!hasScheduleOfferFeatureFlag && (
            <Grid item xs={6}>
              <AcInput
                header="Availability"
                name="playerAvailability"
                value={values.playerAvailability || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                required={values.setAsFree}
                type="number"
                error={
                  touched.playerAvailability &&
                  Boolean(errors.playerAvailability)
                }
                helperText={
                  touched.playerAvailability
                    ? errors.playerAvailability?.toString()
                    : ''
                }
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <AcSelect
              header="Offer Design"
              name="offerUiId"
              value={values.offerUiId}
              onChange={(e) => {
                setCurrentOfferUI(e.target.value as string);
                handleChange(e);
              }}
              onBlur={handleBlur}
              required={true}
              items={getOffersUI?.data?.result
                .filter((oi: OfferUI) => oi.offerUiType === 'SpecialOffer')
                .map((offerUI: OfferUI) => {
                  return {
                    content: offerUI.name,
                    key: offerUI._id,
                    value: offerUI._id
                  };
                })}
              error={touched.offerUiId && Boolean(errors.offerUiId)}
              helperText={touched.offerUiId ? errors.offerUiId?.toString() : ''}
            />
          </Grid>
          <Grid item xs={6}>
            {values.setAsFree ? (
              <AcInput
                header="Price"
                name="price"
                value={values.price === 0 ? 'Free' : values.price.toString()}
                onChange={(e) => {
                  setTouched({
                    ...touched,
                    price: true
                  });
                  handleChange(e);
                  validateField('price');
                }}
                onBlur={handleBlur}
                disabled={true}
                type="text"
                InputProps={{
                  inputProps: {
                    min: 0
                  },
                  readOnly: true
                }}
                error={touched.price && Boolean(errors.price)}
                helperText={touched.price ? errors.price?.toString() : ''}
              />
            ) : (
              <AcInput
                header="Price"
                name="price"
                value={values.price}
                onChange={(e) => {
                  setTouched({
                    ...touched,
                    price: true
                  });
                  handleChange(e);
                  validateField('price');
                }}
                onBlur={handleBlur}
                required={true}
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  inputProps: {
                    min: MIN_USD_PRICE
                  }
                }}
                error={touched.price && Boolean(errors.price)}
                helperText={touched.price ? errors.price?.toString() : ''}
              />
            )}
          </Grid>
        </Grid>
      </AcCard>
      {fetchFeatureFlags.data?.featureFlags?.[
        EFeatureFlag.DASHBOARD_OFFERS_FREE
      ] && (
        <Grid item xs={6}>
          <FormControlLabel
            label="Set as Free"
            style={{
              marginTop: '12px',
              marginLeft: '-2px'
            }}
            control={
              <Checkbox
                name="setAsFree"
                style={{
                  padding: '0'
                }}
                checked={values.setAsFree}
                onBlur={handleBlur}
                onChange={async (e) => {
                  const isChecked = e.target.checked;
                  await setFieldValue('setAsFree', isChecked);

                  // Update related fields programmatically and validate immediately
                  if (isChecked) {
                    await setFieldValue('price', 0);
                    await setFieldValue('playerAvailability', 1);
                  } else {
                    await setFieldValue('price', MIN_USD_PRICE);
                    await setFieldValue('playerAvailability', undefined); // Or set a default valid value
                  }

                  await validateForm(); // Revalidate the entire form to reflect changes
                }}
              />
            }
            componentsProps={{
              typography: {
                fontSize: 14,
                sx: {
                  marginLeft: '2px'
                },
                color: '#717188'
              }
            }}
          />
        </Grid>
      )}
      <Divider />
      <AcCard stackContainer={false} title="Segments">
        <Grid container columnSpacing={1.5}>
          <Grid xs={12} item>
            <AcAutoComplete
              ref={inputRef}
              value={inputValue}
              startAdornment={<GridSearchIcon />}
              label="Segment search / add"
              onEnter={(val) => {
                setChosenSegment([...chosenSegment, val]);
                setInputValue('');
                inputRef?.current?.blur();
              }}
              onChangeEvent={(val) =>
                setSegmentError(
                  chosenSegment.includes(val)
                    ? "You can't add the same segment twice"
                    : ''
                )
              }
              options={
                (!getSegments.isLoading &&
                  getSegments.data?.filter(
                    (s: string) => !chosenSegment.includes(s)
                  )) ||
                []
              }
              additionalOptions={chosenSegment}
              onChange={(e) => {
                const selected = (e.target as HTMLElement).textContent;
                selected && setChosenSegment([...chosenSegment, selected]);
              }}
              onBlur={handleBlur}
              error={segmentError}
            />
            {!!chosenSegment.length && (
              <Stack marginTop={1.5} gap={1} direction="row" flexWrap="wrap">
                {chosenSegment.map((val: string) => (
                  <Chip
                    className="segment-chip"
                    key={val}
                    onClick={() => {
                      setChosenSegment((oldSegments: any) => {
                        const newSegments = oldSegments.filter(
                          (existingVal: string) => existingVal !== val
                        );
                        setFieldValue('segments', newSegments);
                        return newSegments;
                      });
                    }}
                    label={
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        gap={0.5}
                      >
                        <span>{val}</span>
                        <CloseIcon
                          sx={{
                            width: 14,
                            height: 14
                          }}
                        />
                      </Stack>
                    }
                  />
                ))}
              </Stack>
            )}
          </Grid>
        </Grid>
      </AcCard>
      <Divider />
      {relevantBadgeType === EBadgeType.RIBBON && (
        <>
          <AcCard stackContainer={false} title="Badges">
            <Grid container columnSpacing={1.5}>
              <Grid item xs={12}>
                <AcSelect
                  header="Ribbon"
                  name="badge"
                  value={values.badge}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  renderType={EAcSelectItemRenderType.TEXT}
                  items={badgeList}
                  onClear={() => {
                    setFieldValue('badge', null);
                  }}
                />
              </Grid>
            </Grid>
          </AcCard>
          <Divider />
        </>
      )}
      {relevantBadgeType === EBadgeType.EMBLEM && (
        <>
          <AcCard stackContainer={false} title="Badges">
            <Grid container columnSpacing={1.5}>
              <Grid item xs={12}>
                <AcSelect
                  header="Emblem"
                  name="badge"
                  value={values.badge}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  renderType={EAcSelectItemRenderType.TEXT}
                  items={badgeList}
                  onClear={() => {
                    setFieldValue('badge', null);
                  }}
                />
              </Grid>
            </Grid>
          </AcCard>
          <Divider />
        </>
      )}
      <AcCard stackContainer={false} title="Sale & Discounts">
        <Grid container columnSpacing={1.5} mb={2}>
          <Grid item xs={6}>
            <AcInput
              header="Product Sale"
              name="salePercentage"
              value={values.salePercentage}
              onChange={handleChange}
              onBlur={handleBlur}
              type="number"
              InputProps={{
                startAdornment:
                  values?.salePercentageDisplayType ===
                  ESaleDiscountDisplayType.PERCENTAGE ? (
                    <InputAdornment position="start">%</InputAdornment>
                  ) : undefined,
                inputProps: {
                  min: 0,
                  step: 0.1
                }
              }}
              error={touched.salePercentage && Boolean(errors.salePercentage)}
              helperText={
                touched.salePercentage ? errors.salePercentage?.toString() : ''
              }
            />
          </Grid>
          <Grid item xs={6}>
            <DisplayTypeSelect
              handleChange={handleChange}
              handleBlur={handleBlur}
              value={values?.salePercentageDisplayType}
            />
          </Grid>
        </Grid>
        {values.price > 0 && (
          <Grid container columnSpacing={1.5}>
            <Grid item xs={6}>
              <AcInput
                header="Price Discount"
                name="discount"
                value={values.discount}
                onChange={handleChange}
                onBlur={handleBlur}
                type="number"
                InputProps={{
                  startAdornment:
                    values?.priceDiscountDisplayType ===
                    ESaleDiscountDisplayType.PERCENTAGE ? (
                      <InputAdornment position="start">%</InputAdornment>
                    ) : undefined,
                  inputProps: {
                    min: 0,
                    step: 0.1
                  }
                }}
                error={touched.discount && Boolean(errors.discount)}
                helperText={touched.discount ? errors.discount?.toString() : ''}
              />
            </Grid>
            <Grid item xs={6}>
              <DisplayTypeSelect
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values?.priceDiscountDisplayType}
                disabled={true}
              />
            </Grid>
          </Grid>
        )}
      </AcCard>
      <Divider />
      <AcCard stackContainer={false} title="Products">
        <Grid container rowSpacing={1.5} columnSpacing={1.5}>
          <Grid item xs={6}>
            <AcSelect
              header="Product"
              name=""
              value={currentProduct?.productId || ''}
              onChange={(e) => {
                setCurrentProduct({
                  ...currentProduct,
                  productId: (e.target.value as string) || '',
                  quantity: currentProduct?.quantity || 0
                });
              }}
              onBlur={handleBlur}
              renderType={EAcSelectItemRenderType.IMAGE}
              items={
                (!isLoadingProducts &&
                  productsData &&
                  productsData.result
                    ?.map((product: Product) => {
                      if (
                        !products.find(
                          (p: ProductsSequenceProduct) =>
                            p.productId === product.productId
                        )
                      ) {
                        return {
                          url: product.images?.[0]?.url,
                          name: product.name,
                          content: product.name,
                          key: product.productId,
                          value: product.productId
                        };
                      }
                      return undefined;
                    })
                    ?.filter((v: any) => v !== undefined)) ||
                []
              }
            />
          </Grid>

          <Grid item xs={6}>
            <AcInput
              header="Quantity"
              name="quantity"
              value={currentProduct?.quantity}
              type="number"
              inputProps={{
                min: 0
              }}
              onChange={(e) => handleQuantityChange(e)}
              error={touched.quantity && Boolean(errors.quantity)}
              helperText={touched.quantity ? errors.quantity?.toString() : ''}
            />
          </Grid>

          <Grid item xs={12}>
            <ActionButton
              text={'Add Product'}
              variant="outlined"
              disabled={!currentProduct?.productId || !currentProduct?.quantity}
              onClick={addProduct}
            />
          </Grid>

          <Grid item xs={12} mt={-3}>
            <DataTable
              columns={[
                {
                  field: 'image',
                  headerName: '',
                  flex: 1,
                  renderCell: (params: any) => {
                    return (
                      <img
                        style={{
                          maxHeight: '50%',
                          objectFit: 'contain'
                        }}
                        src={params.row.image}
                        alt="productImage"
                      />
                    );
                  }
                },
                {
                  field: 'name',
                  headerName: 'Name',
                  flex: 1
                },
                {
                  field: 'amount',
                  headerName: 'Amount',
                  flex: 1
                },
                {
                  field: 'type',
                  headerName: 'Type',
                  flex: 1
                },
                {
                  field: 'remove',
                  headerName: 'Remove',
                  renderCell: (params) => (
                    <IconButton onClick={() => removeProduct(params.row._id)}>
                      <DeleteIcon />
                    </IconButton>
                  ),
                  flex: 1
                },
                {
                  field: 'edit',
                  headerName: 'Edit',
                  renderCell: (params) => (
                    <IconButton onClick={() => editProduct(params.row._id)}>
                      <EditIcon />
                    </IconButton>
                  ),
                  flex: 1
                }
              ]}
              rows={products}
              loading={false}
            />
          </Grid>
        </Grid>
      </AcCard>
      <Divider />

      {hasFeatureFlagTags && (
        <AcCard stackContainer={false} title="Tags">
          <Grid xs={12} item>
            <AcAutoComplete
              ref={inputRef}
              value={inputValue}
              startAdornment={<GridSearchIcon />}
              helperText="Press ‘Enter’ to add a new tag"
              onEnter={(val) => {
                setFieldValue('tags', [...values.tags, val]);
                setInputValue('');
                inputRef?.current?.blur();
              }}
              onChangeEvent={(val) => {
                setInputValue(val);
                setTagsError(
                  values.tags.includes(val)
                    ? "You can't add the same tag twice"
                    : ''
                );
              }}
              onBlur={handleBlur}
              options={
                (!getTags?.isLoading &&
                  getTags?.data.filter(
                    (s: string) => !values.tags.includes(s)
                  )) ||
                []
              }
              additionalOptions={values.tags}
              onChange={(e) => {
                const selected = (e.target as HTMLElement).textContent;
                selected && setFieldValue('tags', [...values.tags, selected]);
                setInputValue('');
              }}
              error={tagsError}
            />
            {!!values.tags.length && (
              <Stack marginTop={1.5} gap={1} direction="row" flexWrap="wrap">
                {values.tags.map((val: string) => (
                  <Chip
                    className="tags-chip"
                    key={val}
                    onClick={() => removeTag(val)}
                    label={
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        gap={0.5}
                      >
                        <span>{val}</span>
                        <CloseIcon
                          sx={{
                            width: 14,
                            height: 14
                          }}
                        />
                      </Stack>
                    }
                  />
                ))}
              </Stack>
            )}
          </Grid>
        </AcCard>
      )}
    </Stack>
  );
};

export default SettingsTab;
