export const DELETE_LANGUAGE_MODAL_TITLE = 'Please note';
export const DELETE_LANGUAGE_MODAL_TEXT = (
  <>
    Deleting the language will delete all existing translations.
    <br />
    <br />
    Are you sure?
  </>
);
export const EDIT_LANGUAGE_SUCCESS_MSG = 'Language was successfully updated';
export const DELETE_LANGUAGE_SUCCESS_MSG = 'Language was successfully deleted';
