import { useMutation } from '@tanstack/react-query';

import { DisputeEvidence } from '../common/contracts';
import useAxios from '../hooks/useAxios';

import { EApiRoutes, getApiRouteOrMock } from './api.utils';

export default function useDisputes(disputeId?: string, publisherId?: string) {
  const axios = useAxios();

  const deleteDisputeEvidence = useMutation(async (evidenceId: string) => {
    return await axios.del(
      `${getApiRouteOrMock(EApiRoutes.DISPUTES)}/${disputeId}/${evidenceId}`,
      {},
      publisherId ? { 'x-publishers': publisherId } : undefined
    );
  });

  const updateDisputeEvidence = useMutation(
    async (evidence: Partial<DisputeEvidence>) => {
      return await axios.put(
        `${getApiRouteOrMock(EApiRoutes.DISPUTES)}/${disputeId}/${evidence._id}`,
        evidence,
        publisherId ? { 'x-publishers': publisherId } : undefined
      );
    }
  );

  const addDisputeEvidence = useMutation(
    async (newDispute: Partial<DisputeEvidence>) => {
      return await axios.post(
        `${getApiRouteOrMock(EApiRoutes.DISPUTES)}/${disputeId}`,
        newDispute,
        publisherId ? { 'x-publishers': publisherId } : undefined
      );
    },
    {}
  );

  return {
    deleteDisputeEvidence,
    updateDisputeEvidence,
    addDisputeEvidence
  };
}
