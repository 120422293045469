import { Location, NavigateFunction } from 'react-router-dom';

import { SortingOrderValue } from '../../constants/enums';

const fieldToSortingOrderValue: { [key: string]: SortingOrderValue } = {
  bundleName: SortingOrderValue.BUNDLE,
  _id: SortingOrderValue.ID,
  provider: SortingOrderValue.PROVIDER,
  amountFormatted: SortingOrderValue.AMOUNT,
  playerId: SortingOrderValue.PLAYER,
  createdAt: SortingOrderValue.DATE,
  state: SortingOrderValue.STATUS
};

const sortValueToFieldMap: { [key in SortingOrderValue]: string } = {
  [SortingOrderValue.BUNDLE]: 'bundleName',
  [SortingOrderValue.ID]: '_id',
  [SortingOrderValue.PROVIDER]: 'provider',
  [SortingOrderValue.AMOUNT]: 'amountFormatted',
  [SortingOrderValue.PLAYER]: 'playerId',
  [SortingOrderValue.DATE]: 'createdAt',
  [SortingOrderValue.STATUS]: 'state'
};

const updateQueryParam = ({
  paramName,
  paramValue,
  location,
  navigate
}: {
  paramName: string;
  paramValue: string;
  location: Location;
  navigate: NavigateFunction;
}) => {
  const newParams = new URLSearchParams(location.search);

  if (paramValue?.length) {
    newParams.set(paramName, paramValue);
  } else {
    newParams.delete(paramName);
  }

  const navigationOpt: {
    replace: boolean;
    state?: Record<string, boolean>;
  } = { replace: true };
  if (location?.state) {
    navigationOpt.state = location.state;
  }
  navigate(`${location.pathname}?${newParams.toString()}`, navigationOpt);
};

export const ordersUtils = {
  updateQueryParam,
  fieldToSortingOrderValue,
  sortValueToFieldMap
};
