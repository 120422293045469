import { useMutation, useQuery } from '@tanstack/react-query';

import { NEW_VERSION } from '../constants/constants';
import { ELocalStorageKeys, EQueryKeys } from '../constants/enums';
import useAxios from '../hooks/useAxios';
import { localStorageUtil } from '../utils/localStorageUtil';
import { Report } from '../views/Reports/Report.types';

import { EApiRoutes, fetchConfig, getApiRouteOrMock } from './api.utils';

const BASE_URL =
  localStorageUtil.getAny(ELocalStorageKeys.ENVIRONMENT) ||
  process.env.REACT_APP_API_BASE_URL;

export default function useReports(version?: number, projects?: string) {
  const axios = useAxios();
  const reportsRoute =
    version === NEW_VERSION
      ? EApiRoutes.FINANCIAL_REPORTS_NEW_VERSION
      : EApiRoutes.FINANCIAL_REPORTS;

  const getReports = useQuery({
    ...fetchConfig.list,
    refetchInterval: 1000 * 15,
    refetchIntervalInBackground: false,
    retry: false,
    queryKey: [EQueryKeys.FINANCIAL_REPORTS],
    queryFn: async () => {
      return await axios.get(
        getApiRouteOrMock(reportsRoute),
        undefined,
        projects ? { 'x-publishers': projects } : undefined
      );
    }
  });

  const getReportDownload = (reportId: string) => {
    window.open(
      `${BASE_URL}${getApiRouteOrMock(reportsRoute)}/${reportId}/download`,
      '_blank'
    );
  };

  const createReport = useMutation(
    async (params: { data: Pick<Report, 'interval'>; projects?: string }) => {
      const reportsParams =
        version === NEW_VERSION ? params.data.interval : params.data;
      return await axios.post(
        getApiRouteOrMock(reportsRoute),
        reportsParams,
        params?.projects ? { 'x-publishers': params?.projects } : undefined
      );
    },
    {}
  );

  const deleteReport = useMutation(async (reportId: string) => {
    return await axios.del(`${getApiRouteOrMock(reportsRoute)}/${reportId}`);
  }, {});

  return {
    getReports,
    createReport,
    deleteReport,
    getReportDownload
  };
}
