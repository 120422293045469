import { Project } from '../common/contracts';

export const getSelectProjectOptions = (
  projectsDetails: Project[],
  allowedProjects: string[] = []
) => {
  const allProjectsOption = projectsDetails.reduce(
    (acc, { publisherId }, index) => {
      const separator = index === projectsDetails.length - 1 ? '' : ',';
      return acc + `${publisherId}${separator}`;
    },
    ''
  );

  const projectOptions = [
    {
      content: 'All projects',
      key: 'all',
      value: allProjectsOption,
      filterBy: allProjectsOption
    }
  ];
  const newOptions = projectsDetails.map(({ publisherId, publisherName }) => {
    return {
      content: publisherName,
      key: publisherId,
      value: publisherId,
      filterBy: publisherId
    };
  });

  const po = projectOptions.concat(newOptions).filter((project) => {
    return allowedProjects.length > 0
      ? allowedProjects.includes(project.value)
      : true;
  });

  return po;
};

export const getProjectGUIDfromURL = () => {
  const regex = /project\/([a-fA-F0-9]+)\//;
  const urlObj = new URL(window.location.href);
  const path = urlObj.pathname;
  const match = path.match(regex);

  if (match) {
    return match[1];
  } else {
    return null;
  }
};
