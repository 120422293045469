import { CloseOutlined } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

import ActionButton from '../ActionButton/ActionButton';

import { DialogProps } from './Dialog.types';

import './style.scss';

const DialogModal = ({
  headline,
  text,
  content,
  buttons,
  isOpen,
  closeDialog,
  width = 'unset',
  withDivider = true,
  closeButton = false,
  className
}: DialogProps) => {
  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      PaperProps={{ style: { width: width } }}
      className={className ?? ''}
    >
      <div
        className="dialog-header"
        style={{
          borderBottom: withDivider ? '1px solid #bebebe' : 'unset'
        }}
      >
        <DialogTitle>{headline}</DialogTitle>
        {closeButton && (
          <CloseOutlined cursor={'pointer'} onClick={closeDialog} />
        )}
      </div>
      <DialogContent>
        {text && <DialogContentText>{text}</DialogContentText>}
        {content && content}
      </DialogContent>
      {buttons && buttons?.length > 0 && (
        <DialogActions>
          {buttons.map((button, i) => (
            <ActionButton
              key={i}
              color={button.color}
              onClick={button.func}
              variant={button.variant}
              text={button.text}
              disabled={button.disabled}
              fullWidth={button.fullWidth}
            />
          ))}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DialogModal;
